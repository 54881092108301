<template>
  <v-card class="minContent">
    <v-card-title>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :disabled="selected.length === 0"
            color="secondary"
          >
            <v-icon class="mr-2">mdi-menu-down</v-icon>
            {{ $t("base.actions") }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            @click="editGroup()"
            :disabled="selected.length !== 1"
          >
            <v-icon class="mr-4">mdi-pencil</v-icon>
            {{ $t("accAdmin.group.edit")}}
          </v-list-item>
          <v-list-item @click="deleteGroups()">
            <v-icon class="mr-4">mdi-delete-forever</v-icon>
            {{ $t("accAdmin.group.delete")}}
          </v-list-item>
        </v-list>
      </v-menu>
      <span class="ml-8 body-1">
        {{ selected.length + " " + $t('base.of') + " " + groupList.length + " " + $t('base.selected') }}
      </span>
      <v-spacer />
      <v-btn
        @click="$router.push('/accAdmin/groups/create')"
        color="secondary"
        class="mr-8"
      >
        <v-icon class="mr-2">mdi-plus-box</v-icon>
        {{ $t("accAdmin.group.new") }}
      </v-btn>
      <v-icon
        @click="getGroupList()"
        color="secondary"
        class="mr-8"
      >mdi-reload</v-icon>
    </v-card-title>

    <v-card-text class="pt-4">
      <v-row no-gutters>
        <v-col cols="12">
          <v-data-table
            v-model="selected"
            :headers="listHeaders"
            :items="groupList"
            :items-per-page="15"
            :footer-props="footerProps"
            item-key="uid"
            show-select
            fixed-header
            :sort-by="listSortKey"
            @click:row="clickedRow"
            :loading="$store.state.base.admin.loadingGroups"
          >
            <template v-slot:body.prepend="{}">
              <tr>
                <td></td>
                <td
                  v-for="(h, index) in listHeaders"
                  :key="index"
                >
                  <SearchField
                    v-if="h.value === 'name'"
                    :value="filter.name"
                    @filter="applyFilters($event, 'name')"
                  />
                  <v-icon
                    v-if="h.value === 'menu'"
                    @click="clearFilters()"
                  >mdi-magnify-minus</v-icon>
                </td>
              </tr>
            </template>
            <template v-slot:header.data-table-select="{ on, props }">
              <v-simple-checkbox
                color="secondary"
                v-bind="props"
                v-on="on"
              ></v-simple-checkbox>
            </template>
            <template v-slot:header.userCount="{}">
              <v-icon class="primaryContrast--text text--lighten-3">mdi-account-multiple</v-icon>
            </template>
            <template v-slot:header.menu="{}">
            </template>
            <template v-slot:item.menu="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="editGroup(item)">
                    <v-icon class="mr-4">mdi-pencil</v-icon>
                    {{ $t("accAdmin.group.edit")}}
                  </v-list-item>
                  <v-list-item @click="deleteGroup(item)">
                    <v-icon class="mr-4">mdi-delete-forever</v-icon>
                    {{ $t("accAdmin.group.delete")}}
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="js">
import { loadGroupList } from '@/baseAdmin/js/baseAdminHelper';
import { simpleFilter } from '@/base/js/ObjectHelper';
import SearchField from '@/common/SearchField';

export default {
  name: 'GroupTable',
  components: {
    SearchField
  },
  data () {
    return {
      selected: [],
      listSortKey: 'name',
      filter: {
        name: ''
      },
      footerProps: {
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [15, 30, 50, -1]
      },
      availableHeaders: [
        { text: this.$t('acc.group.name'), value: 'name' },
        { text: this.$t('acc.group.userCount'), value: 'userCount' }
      ],
      activeListHeaders: [true, true]
    };
  },
  computed: {
    groupList () {
      return simpleFilter(this.$store.state.base.admin.administratedOrgObjects.groupList.filter(
        (g) => g.uid !== this.$store.state.base.admin.administratedOrgObjects.defaultGroup
      ), this.filter);
    },
    listHeaders () {
      const h = [];
      for (let i = 0; i < this.activeListHeaders.length; i++) {
        if (this.activeListHeaders[i]) {
          h.push(this.availableHeaders[i]);
        }
      }
      h.push({ text: 'menu', value: 'menu', sortable: false });
      return h;
    }
  },
  mounted () {
    if (!this.$store.state.base.admin.loadingGroups && this.$store.state.base.admin.administratedOrgObjects.groupList.length === 0) {
      this.getGroupList();
    };
  },
  methods: {
    async getGroupList () {
      // ToDo: invoke only if really neccessary (use of isDirty-flag)
      loadGroupList(this.$store.state.base.admin.administratedOrg.uid).then(
        () => {}
      ).catch(
        (messages) => {
          this.$globals.Info.open(messages);
        }
      );
    },
    async deleteGroups () {
      if (await this.$globals.Confirm.yesNoDlg(this.$t('accAdmin.group.delete'), this.$t('accAdmin.group.groupsDeleteConfirmText'))) {
        for (const group of this.selected) {
          const ioData = {
            targetOrg: {
              uid: this.$store.state.base.admin.administratedOrg.uid
            },
            targetGroup: {
              uid: group.uid
            }
          };
          this.$restClient.callProcess('accAdmin', 'userGroupDelete', ioData).then(
            (processData) => {
              // if (isProcessStateOK(processData)) {
              // }
              this.$globals.Info.open(processData.processState.messages);

              this.$store.state.base.admin.administratedOrgObjects.groupList.splice(
                this.$store.state.base.admin.administratedOrgObjects.groupList.findIndex((g) => g.uid === group.uid), 1
              );
            }
          );
        }
      }
    },
    async deleteGroup (group) {
      // show confirmation dialog
      if (await this.$globals.Confirm.yesNoDlg(this.$t('accAdmin.group.delete') + ': ' + group.name, this.$t('accAdmin.group.groupDeleteConfirmText'))) {
        const ioData = {
          targetOrg: {
            uid: this.$store.state.base.admin.administratedOrg.uid
          },
          targetGroup: {
            uid: group.uid
          }
        };
        this.$restClient.callProcess('accAdmin', 'userGroupDelete', ioData).then(
          (processData) => {
            // if (isProcessStateOK(processData)) {
            // }
            this.$globals.Info.open(processData.processState.messages);
            this.$store.state.base.admin.administratedOrgObjects.groupList.splice(
              this.$store.state.base.admin.administratedOrgObjects.groupList.findIndex((g) => g.uid === group.uid), 1
            );
          }
        );
      }
    },
    editGroup (group = null) {
      if (!group) {
        group = this.selected[0];
      }
      this.$router.push('/accAdmin/groups/' + group.uid);
      // this.$emit('edit', group);
    },
    clickedRow (group) {
      this.editGroup(group);
    },
    clearFilters () {
      this.filter.name = '';
      this.applyFilters();
    },
    applyFilters (value, filtername) {
      this.filter[filtername] = value;
      this.$logger.debug('accAdmin', 'applyFilters', this.filter.name);
    }
  }
};
</script>

<style scoped>
</style>
