<template>
  <v-card>
    <v-card-text
      class="minContent"
      v-if="tempTargetSettings != null"
    >
      <v-row>
        <v-col>
          {{ $t('accAdmin.orgSettings.hint') }}
        </v-col>
      </v-row>
      <!--<v-row v-if="((targetOrgUid !== $store.state.base.org.uid && tempTargetSettings.moduleSettings === 'setByParent') || isRoot)">
        <v-col>
          < !-- portal information -- >
          <v-checkbox
            v-if="isRoot"
            v-model="tempTargetSettings.ownPortal"
            :label="$t('accAdmin.orgSettings.ownPortal')"
          />
          <v-text-field
            v-if="isRoot"
            v-model="tempTargetSettings.portalUrl"
            :label="$t('accAdmin.orgSettings.portalUrl')"
            :hint="$t('accAdmin.orgSettings.portalUrlHint')"
            class="px-8 pb-4"
          />
          < !-- default Contact (shown in Footer) -- >
          <v-checkbox
            v-if="isRoot"
            v-model="tempTargetSettings.ownDefaultContact"
            @change="ownDefaultContactChanged"
            :label="$t('accAdmin.orgSettings.ownDefaultContact')"
          />
          <v-text-field
            v-if="isRoot"
            v-model="tempTargetSettings.defaultContact"
            :label="$t('accAdmin.orgSettings.defaultContact')"
            :hint="$t('accAdmin.orgSettings.defaultContactHint')"
            class="px-8 pb-4"
          />
        </v-col>
      </v-row>-->
      <v-row v-if="isRoot || targetOrgUid !== $store.state.base.org.uid">
        <v-col>
          <!-- theme settings -->
          <v-label>{{ $t('accAdmin.orgSettings.themeSettings') }}</v-label>
          <v-radio-group
            :disabled="isRoot && targetOrgUid === $store.state.base.org.uid"
            v-model="tempTargetSettings.theme.state"
            :mandatory="true"
            class="pb-6"
          >
            <v-radio
              :label="$t('accAdmin.orgSettings.inheritFromParent')"
              value="inheritFromParent"
            />
            <v-radio
              :label="$t('accAdmin.orgSettings.setByParent')"
              value="setByParent"
            />
            <v-radio
              :label="$t('accAdmin.orgSettings.setInAccount')"
              value="setInAccount"
            />
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row v-if="isRoot">
        <v-col>
          <!-- module settings -->
          <v-label>{{ $t('accAdmin.orgSettings.moduleSettings') }}</v-label>
          <v-radio-group
            :disabled="isRoot && targetOrgUid === $store.state.base.org.uid"
            v-model="tempTargetSettings.moduleSettings"
            @change="$emit('moduleSettingsChanged', $event)"
            :mandatory="true"
            class="pb-6"
          >
            <v-radio
              :label="$t('accAdmin.orgSettings.inheritFromParent')"
              value="inheritFromParent"
            />
            <v-radio
              :label="$t('accAdmin.orgSettings.setByParent')"
              value="setByParent"
            />
            <v-radio
              v-if="isRoot && targetOrgUid === $store.state.base.org.uid"
              :label="$t('accAdmin.orgSettings.setInAccount')"
              value="setInAccount"
            />
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row v-if="isRoot">
        <v-col>
          <!-- subOrg settings -->
          <v-label>{{ $t('accAdmin.orgSettings.subOrgSettings') }}</v-label>
          <v-checkbox
            :disabled="isRoot && targetOrgUid === $store.state.base.org.uid"
            v-model="tempTargetSettings.subOrgSettings.createSubOrgs"
            :label="$t('accAdmin.orgSettings.createSubOrgs')"
          />
          <v-checkbox
            :disabled="isRoot && targetOrgUid === $store.state.base.org.uid"
            v-if="tempTargetSettings.subOrgSettings.createSubOrgs === true"
            v-model="tempTargetSettings.subOrgSettings.createSubOrgsRecursive"
            :label="$t('accAdmin.orgSettings.createSubOrgsRecursive')"
          />
        </v-col>
      </v-row>
      <v-row v-if="isRoot">
        <v-col>
          <!-- subOrg settings -->
          <v-label>{{ $t('accAdmin.orgSettings.countrySettings') }}</v-label>
          <v-checkbox
            :disabled="isRoot && targetOrgUid === $store.state.base.org.uid"
            v-model="tempTargetSettings.countrySettings.show"
            :label="$t('accAdmin.orgSettings.setCountrySettings')"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <!-- save or cancel editing -->
    <v-card-text>
      <SaveCancelButtonBar
        class="mt-4"
        :cancel-text="$t('base.back')"
        @cancel="$emit('cancel')"
        @save="save()"
      />
    </v-card-text>
  </v-card>
</template>

<script lang="js">
import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';

export default {
  name: 'OrgSettings',
  components: {
    SaveCancelButtonBar
  },
  props: {
    targetOrgUid: { type: String, required: true },
    targetSettings: { type: Object, required: true },
    parentOrgUid: { type: String, default: null },
    parentSettings: { type: Object, default: null },
    isRoot: { type: Boolean, default: false }
  },
  data () {
    return {
      tempTargetSettings: null
    };
  },
  computed: {
    adminSettings () {
      return this.$store.state.base.orgSettings;
    }
  },
  watch: {
    targetSettings () {
      this.tempTargetSettings = JSON.parse(JSON.stringify(this.targetSettings));
    }
  },
  async mounted () {
    this.tempTargetSettings = JSON.parse(JSON.stringify(this.targetSettings));
    if (this.tempTargetSettings.ownDefaultContact == null) {
      this.tempTargetSettings.ownDefaultContact = false;
      this.tempTargetSettings.defaultContact = this.parentSettings.defaultContact;
    }
  },
  methods: {
    async save () {
      // set correct orgUids to settings
      if (!this.tempTargetSettings.ownPortal && !this.isRoot) {
        this.tempTargetSettings.portalUrl = this.parentSettings.portalUrl;
      }

      if (this.tempTargetSettings.social.active) {
        if (this.isRoot) this.tempTargetSettings.social.orgUid = this.targetOrgUid;
        else this.tempTargetSettings.social.orgUid = this.parentSettings.social.orgUid;
      } else this.tempTargetSettings.social.orgUid = '';

      if (this.tempTargetSettings.forum.state === 'parent') this.tempTargetSettings.forum.orgUid = this.parentSettings.forum.orgUid;
      else if (this.tempTargetSettings.forum.state === 'own') this.tempTargetSettings.forum.orgUid = this.targetOrgUid;
      else this.tempTargetSettings.forum.orgUid = '';

      if (this.isRoot === true && this.targetOrgUid === this.$store.state.base.org.uid) {
        this.tempTargetSettings.subOrgSettings.createSubOrgs = true;
        this.tempTargetSettings.subOrgSettings.createSubOrgsRecursive = false;
      } else if (this.tempTargetSettings.subOrgSettings.createSubOrgs !== true) {
        this.tempTargetSettings.subOrgSettings.createSubOrgsRecursive = false;
      }

      this.tempTargetSettings.parentUid = this.parentOrgUid;

      this.$emit('update', this.tempTargetSettings);
    },
    editDisabled () {
      if (this.$store.state.base.org.uid === this.targetOrgUid) {
        return true;
      }
      return false;
    },
    ownDefaultContactChanged () {
      if (this.tempTargetSettings.ownDefaultContact !== true) {
        this.tempTargetSettings.defaultContact = this.parentSettings.defaultContact;
      }
    }
  }
};
</script>

<style scoped>
</style>
