<template>
  <v-card>
    <v-card-text
      class="minContent"
      v-if="configurableModules.length > 0"
    >
      <v-row
        v-for="(mod, index) in configurableModules"
        :key="index"
      >
        <v-col>
          <v-checkbox
            v-model="assignedModules"
            :label="$t('base.moduleList.' + mod.uid)"
            :value="mod.uid"
            :disabled="mod.default || targetOrgUid === $store.state.base.org.uid"
          ></v-checkbox>
          <component
            class="pr-0 pl-8"
            v-bind:is="mod.settingsComponent"
            :ref="mod.ref"
            :targetOrgShortName="targetOrgShortName"
            :orgSettings="moduleOrgSettings[mod.ref] || {}"
            v-show="assignedModules.includes(mod.uid)"
          ></component>
          <component
            class="pr-0 pl-8"
            v-bind:is="mod.adminSettingsComponent"
            :ref="mod.adminRef"
            :targetOrgShortName="targetOrgShortName"
            :orgSettings="moduleOrgSettings[mod.adminRef] || {}"
            v-show="assignedModules.includes(mod.uid)"
          ></component>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- save or cancel editing -->
    <v-card-text>
      <SaveCancelButtonBar
        class="mt-4"
        :cancel-text="$t('base.back')"
        @cancel="$emit('cancel')"
        @save="save()"
      />
    </v-card-text>
  </v-card>
</template>

<script lang="js">
import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';

export default {
  name: 'OrgModules',
  components: {
    SaveCancelButtonBar
  },
  props: {
    targetOrgUid: { type: String, required: true },
    targetOrgShortName: { type: String, required: true },
    targetOrgModules: { type: Array, required: true },
    moduleOrgSettings: { type: Object, required: true },
    availableModules: { type: Array, required: true }
  },
  data () {
    return {
      assignedModules: []
    };
  },
  computed: {
    configurableModules () {
      return this.availableModules.filter((m) => m.default === false || m.ref != null || m.adminRef != null);
    }
  },
  mounted () {
    for (let moduleUid of this.$configHelper.getBaseModules()) {
      if (this.targetOrgModules.includes(moduleUid)) {
        this.assignedModules.push(moduleUid);
      }
    }
  },
  methods: {
    async save () {
      this.$logger.debug('accAdmin', 'saving modules: ', this.assignedModules);
      for (let mod of this.availableModules) {
        // save globalProps for standardModule
        if (mod.ref != null) {
          let orgSettings = this.$refs[mod.ref][0].getOrgSettings();
          let ioData = {
            targetKey: this.targetOrgUid,
            context: mod.ref,
            globalProps: orgSettings,
            moduleUid: mod.uid
          };
          this.$emit('saveModuleOrgSettings', ioData);
        }
        // save globalProps for adminModule
        if (mod.adminRef != null) {
          let orgSettings = this.$refs[mod.adminRef][0].getOrgSettings();
          let ioData = {
            targetKey: this.targetOrgUid,
            context: mod.adminRef,
            globalProps: orgSettings,
            moduleUid: mod.uid
          };
          this.$emit('saveModuleOrgSettings', ioData);
        }
      }
      // send data to backend
      let ioData = {
        targetOrg: this.targetOrgUid,
        modules: this.assignedModules
      };
      this.$restClient.callProcess('accAdmin', 'organisationSetModules', ioData).then(
        (processData) => {
          if (isProcessStateOK(processData)) {
            this.$globals.Confirm.okDlg(this.$t('accAdmin.org.updateData'), this.$t('accAdmin.org.updateSuccess'));
            this.$emit('changed', this.assignedModules);
          } else {
            this.$globals.Info.open(processData.processState.messages);
          }
        }
      );
    }
  }
};
</script>

<style scoped>
</style>
