<template>
  <v-card>
    <v-card-text class="minContent">
      <v-row>
        <v-col sm="4">
          <v-switch
            class="mb-6 mr-4 align-center"
            color="secondary"
            v-model="darkTheme"
            label="Dark Theme"
          />
          <v-btn
            @click.native="selectColorProp('primary')"
            :color="currentTheme['primary']"
            class="mb-8"
            :style="styleText('primary', true) + ' width: 100%'"
          >
            {{ $t('accAdmin.theme.primary') }}
          </v-btn>
          <!--<v-btn
              @click.native="selectColorProp(colorProp + 'Contrast')"
              :color="currentTheme[colorProp + 'Contrast']"
              block
              :style="styleText(colorProp, false) + ' width: 100%'"
            >
              {{ $t('accAdmin.theme.' + colorProp + 'Contrast') }}
            </v-btn>-->
          <v-btn
            @click.native="selectColorProp('secondary')"
            :color="currentTheme['secondary']"
            :style="styleText('secondary', true) + ' width: 100%'"
            class="mb-8"
          >
            {{ $t('accAdmin.theme.secondary') }}
          </v-btn>
          <!--<v-btn
              @click.native="selectColorProp(colorProp + 'Contrast')"
              :color="currentTheme[colorProp + 'Contrast']"
              block
              :style="styleText(colorProp, false) + ' width: 100%'"
            >
              {{ $t('accAdmin.theme.' + colorProp + 'Contrast') }}
            </v-btn>-->
          <v-row no-gutters>
            <v-col>
              <v-btn
                @click.native="selectColorProp('accent')"
                :key="currentTheme['accent']"
                :color="currentTheme['accent']"
                class="mb-8"
                :style="'color: ' + accentTextColor + '; width: 100%;'"
              >
                {{ $t('accAdmin.theme.accent') }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                @click.native="selectColorProp('accent')"
                :color="currentTheme['accentContrast']"
                class="mb-8"
                style="width: 100%"
              >
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="text-right mt-4">
            <v-col>
              <v-btn
                @click.native='previewTheme()'
                class="mr-4 prio2"
              >{{ $t('base.preview') }}</v-btn>
              <v-btn
                @click.native='resetTheme()'
                class=" prio2"
              >{{ $t('base.reset') }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="8">
          <span class="title">{{ getText(selectedProp) }}</span>
          <v-color-picker
            v-model="color"
            mode="hexa"
            hide-canvas
            hide-mode-switch
            show-swatches
            swatches-max-height="600px"
            width="100%"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <!-- save or cancel editing -->
    <v-card-text>
      <SaveCancelButtonBar
        class="mt-4"
        :cancel-text="$t('base.back')"
        @cancel="$emit('cancel')"
        @save="save()"
      />
    </v-card-text>
  </v-card>
</template>

<script lang='js'>
import { loadTheme } from '@/base/js/ThemeLoader';
import { S3Helper } from '@/base/js/aws/S3Helper';
import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';

export default {
  name: 'ThemeCreator',
  components: {
    SaveCancelButtonBar
  },
  props: {
    targetOrg: { type: Object, required: true },
    themeKey: { type: String, required: true }
  },
  data () {
    return {
      colorPropList: ['primary', 'secondary', 'accent'],
      selectedProp: 'primary',
      selectedColor: '#FF9200',
      colorTheme: {},
      currentTheme: {},
      darkTheme: false
    };
  },
  computed: {
    color: {
      get () {
        return this.selectedColor;
      },
      set (color) {
        let contrastColor;
        this.selectedColor = color;
        if (typeof color === 'string') {
          this.currentTheme[this.selectedProp] = color;
          contrastColor = this.getContrastColor(color);
        } else {
          this.currentTheme[this.selectedProp] = color.hex;
          contrastColor = this.getContrastColor(color.hex);
        }
        this.currentTheme[this.selectedProp + 'Contrast'] = contrastColor;
        this.$logger.debug('accAdmin', 'changed color: ', this.darkTheme ? 'dark' : 'light', this.selectedProp, color, 'contrast', contrastColor);
      }
    },
    accentTextColor () {
      if (typeof this.currentTheme.accent === 'undefined') return '#121212';
      return this.getContrastColor(this.currentTheme.accent, 'accent', true);
    }
  },
  mounted () {
    this.darkTheme = this.$store.state.base.darkTheme;
    this.init(this.$route.query.resetPreview);
  },
  watch: {
    themeKey () {
      this.init();
    },
    darkTheme () {
      this.currentTheme = this.darkTheme ? this.colorTheme.dark : this.colorTheme.light;
    // },
    // '$route.query.resetPreview': function () {
    //   this.init(this.$route.query.resetPreview);
    }
  },
  methods: {
    async init (resetPreview = false) {
      try {
        if (this.$store.state.base.previewTheme || resetPreview) {
          this.colorTheme = this.$store.state.base.admin.themes.preview.colorTheme;
          this.darkTheme = this.$store.state.base.admin.themes.darkTheme;
          if (resetPreview) {
            this.resetTheme(true);
            let x = this.$store.state.base.previewTheme;
            this.$router.replace({ query: { ...this.$route.query, x } });
          }
        } else {
          this.colorTheme = await loadTheme(this.themeKey);
          if (typeof this.colorTheme.dark === 'undefined' || this.colorTheme.dark == null) {
            throw new Error('theme scheme ist invalid');
          }
        }
      } catch (e) {
        this.$logger.error('config', e.message);
        this.colorTheme = this.$configHelper.getConfigParam('theme');
        // this.colorTheme = this.$vuetify.theme.default.light;
      }
      this.currentTheme = this.darkTheme ? this.colorTheme.dark : this.colorTheme.light;
      this.selectedColor = this.currentTheme[this.selectedProp];
      for (let prop of this.colorPropList) {
        if (typeof this.colorTheme.light === 'undefined') {
          this.colorTheme.light[prop] = '#7A7A7A';
          this.colorTheme.light[prop + 'Contrast'] = '#FFFFFF';
        }
        if (typeof this.colorTheme.dark === 'undefined') {
          this.colorTheme.dark[prop] = '#999999';
          this.colorTheme.dark[prop + 'Contrast'] = '#121212';
        }
      }
    },
    selectColorProp (colorProp) {
      this.selectedProp = colorProp;
      if (typeof this.currentTheme[colorProp] === 'undefined') {
        this.selectedColor = '#999999';
      } else {
        this.selectedColor = this.currentTheme[colorProp];
      }
    },
    getText (colorProp) {
      if (colorProp.substr(colorProp.length - 8) === 'Contrast') {
        return this.$t('accAdmin.theme.' + colorProp.substr(0, colorProp.length - 8)) + ' - ' + this.$t('accAdmin.theme.' + colorProp);
      }
      return this.$t('accAdmin.theme.' + colorProp);
    },
    styleText (colorProp, isContrast) {
      let p = colorProp;
      if (isContrast) p += 'Contrast';
      return 'color: ' + this.currentTheme[p] + ';';
    },
    async save () {
      try {
        let assets = this.$configHelper.getModuleConfigParam('acc', 'assets');
        await S3Helper.uploadJson(
          assets.pathOrgTheme + this.targetOrg.shortname,
          this.colorTheme,
          assets.s3config,
          true
        );
        // if edited org is current org, set new theme
        if (this.targetOrg.uid === this.$store.state.base.org.uid) {
          this.$vuetify.theme.setTheme('light', this.colorTheme.light);
          this.$vuetify.theme.setTheme('dark', this.colorTheme.dark);
        } else {
          // ... else reset current orgs theme
          this.$store.state.base.previewTheme = false;
        }
        if (this.themeKey !== this.targetOrg.shortname) this.$emit('themeUpdated', this.targetOrg.shortname);
      } catch (e) {
        this.$globals.Info.open({ type: 'systemError', message: e.message });
      }
    },
    previewTheme () {
      let previewThemes = {
        darkTheme: this.$store.state.base.darkTheme,
        preview: {
          orgUid: this.targetOrg.uid,
          colorTheme: this.colorTheme
        }
      };
      if (!this.$store.state.base.previewTheme) {
        previewThemes.original = {
          dark: this.$vuetify.theme.themes.dark,
          light: this.$vuetify.theme.themes.light
        };
      }

      this.$store.state.base.admin.themes = previewThemes;
      this.$store.state.base.admin.lockAdminOrgSelector = true;

      // console.log('Preview Theme', this.$store.state.base.previewTheme, JSON.stringify(previewThemes, null, 2));

      this.$store.state.base.previewTheme = true;
      // let mode = this.$store.state.base.darkTheme ? 'dark' : 'light';
      this.$vuetify.theme.setTheme('light', this.colorTheme.light);
      this.$vuetify.theme.setTheme('dark', this.colorTheme.dark);
      this.$vuetify.theme.dark = this.darkTheme;
    },
    resetTheme (resetPreview = false) {
      // if (this.$store.state.base.admin.themes) {
      //   this.$vuetify.theme.setTheme('dark', this.$store.state.base.admin.themes.original.dark);
      //   this.$vuetify.theme.setTheme('light', this.$store.state.base.admin.themes.original.light);
      // }
      if (this.$store.state.base.previewTheme || resetPreview) {
        this.$store.state.base.previewTheme = false;
        this.colorTheme = this.$store.state.base.admin.themes.preview.colorTheme;

        this.currentTheme = this.darkTheme ? this.colorTheme.dark : this.colorTheme.light;
        this.$store.state.base.darkTheme = this.$store.state.base.admin.themes.darkTheme;
        this.$vuetify.theme.dark = this.$store.state.base.darkTheme;
      } else {
        this.init();
      }
      this.$store.state.base.admin.lockAdminOrgSelector = false;
      this.$store.state.base.admin.themes = null;
    },
    getContrastColor (color, selectedProp = null, text = false) {
      if (!selectedProp) selectedProp = this.selectedProp;
      let contrastColor = color;
      let rgb = this.hexToRgb(color);
      let brightness = Math.round((parseInt(rgb.r) * 0.299) +
                        (parseInt(rgb.g) * 0.587) +
                        (parseInt(rgb.b) * 0.114));

      if (selectedProp === 'accent' && !text) { // lighten or darken selected color
        let mod = Math.floor((255 - brightness) / 2);
        if (this.darkTheme) { // darker contrastColor
          mod = Math.floor(brightness / -2);
        }
        let newRgb = {
          r: (rgb.r + mod) > 0 ? ((rgb.r + mod) <= 255 ? rgb.r + mod : 255) : 0,
          g: (rgb.g + mod) > 0 ? ((rgb.g + mod) <= 255 ? rgb.g + mod : 255) : 0,
          b: (rgb.b + mod) > 0 ? ((rgb.b + mod) <= 255 ? rgb.b + mod : 255) : 0
        };
        contrastColor = '#' + ((1 << 24) + (newRgb.r << 16) + (newRgb.g << 8) + newRgb.b).toString(16).slice(1);
        // console.log('accent: ', color, '=', JSON.stringify(rgb), ' => ', brightness, ' => ', contrastColor, '=', JSON.stringify(newRgb));
      } else { // decide between white or dark text color
        contrastColor = (brightness > 160 /* 125 */) ? '#121212' : '#FFFFFF';
        // console.log(selectedProp, ': ', color, '=', JSON.stringify(rgb), ' => ', brightness, ' => ', contrastColor);
      }
      return contrastColor;
    },
    hexToRgb (hex) {
      // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
      let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
      });

      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    }
  }
};
</script>

<style scoped>
</style>
