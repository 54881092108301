<template>
  <v-card>
    <v-tabs v-model="tab">
      <v-tab href="#details">
        {{ $t('accAdmin.group.details') }}
      </v-tab>
      <v-tab
        href="#users"
        v-if="group.uid"
      >
        {{ $t('accAdmin.group.users') }}
      </v-tab>
      <!--<v-tab
        href="#rights"
        v-if="group.uid"
      >
        {{ $t('accAdmin.group.rights') }}
      </v-tab>-->
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="details">
        <v-card>
          <v-card-text class="minContent">
            <v-text-field
              v-model="group.name"
              :label="$t('acc.group.name')"
            />
            <v-text-field
              v-model="group.description"
              :label="$t('acc.group.description')"
            />
          </v-card-text>
          <!-- save or cancel editing -->
          <v-card-text>
            <SaveCancelButtonBar
              class="mt-4"
              :cancel-text="$t('base.back')"
              @cancel="$router.back()"
              @save="save()"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item
        value="users"
        v-if="group.uid"
      >
        <GroupUserTable
          :targetGroupId="group.uid"
          :targetOrgUid="targetOrgUid"
        />
      </v-tab-item>
      <!--<v-tab-item
        value="rights"
        v-if="group.uid"
      >
        <GroupRights
          :targetGroupId="group.uid"
          :targetOrgUid="targetOrgUid"
        />
      </v-tab-item>-->
    </v-tabs-items>
  </v-card>
</template>

<script lang="js">
import GroupUserTable from '@/accAdmin/components/GroupUserTable.vue';
// import GroupRights from '@/accAdmin/components/GroupRights.vue';
import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';

import { isProcessStateOK } from '@/base/js/ProcessDataHelper';

export default {
  name: 'GroupDetails',
  components: {
    GroupUserTable,
    // GroupRights,
    SaveCancelButtonBar
  },
  props: {
    // targetOrgUid: { type: String, required: true },
    targetGroupId: { type: String, default: () => { return null; } }
  },
  data () {
    return {
      group: {
        uid: null,
        name: '',
        description: ''
      }
    };
  },
  computed: {
    tab: {
      set (tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get () {
        return this.$route.query.tab;
      }
    },
    targetOrgUid () {
      return this.$store.state.base.admin.administratedOrg.uid;
    },
    listHeaders () {
      const h = [];
      for (let i = 0; i < this.activeListHeaders.length; i++) {
        if (this.activeListHeaders[i]) {
          h.push(this.availableHeaders[i]);
        }
      }
      h.push({ text: 'menu', value: 'menu', sortable: false });
      return h;
    }
  },
  async mounted () {
    if (this.targetGroupId) {
      // load groupDetails
      await this.loadGroup();
    } else {
      this.$emit('setPageMode', 'create');
    }
  },
  methods: {
    async loadGroup () {
      const ioData = {
        targetOrg: {
          uid: this.targetOrgUid
        },
        targetGroup: this.targetGroupId
      };
      this.$restClient.callProcess('accAdmin', 'userGroupGet', ioData).then(
        (processData) => {
          if (isProcessStateOK(processData)) {
            this.group = processData.ioData.targetGroup;
            this.$emit('setPageMode', 'edit', this.group.name);
          }
          this.$globals.Info.open(processData.processState.messages);
        }
      );
    },
    save () {
      const ioData = {
        targetOrg: {
          uid: this.targetOrgUid
        },
        targetGroup: this.group
      };
      let processName = 'userGroupUpdate';
      if (!this.group.uid) {
        processName = 'userGroupCreate';
      }
      this.$restClient.callProcess('accAdmin', processName, ioData, true).then(
        (processData) => {
          if (isProcessStateOK(processData) && processName === 'userGroupCreate') {
            this.group.uid = processData.ioData.targetGroup.uid;
            this.group.userCount = 0;
            this.$store.state.base.admin.administratedOrgObjects.groupList.push(this.group);
          }
          this.$globals.Info.open(processData.processState.messages);
        }
      );
    }
  }
};
</script>

<style scoped>
</style>
