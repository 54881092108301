<template>
  <v-card>
    <v-card-text class="minContent">
      <v-row>
        <v-col
          md="6"
          sm="12"
          xs="12"
        >
          <v-row>
            <v-col>
              <v-text-field
                v-model="tempOrg.name"
                :label="$t('accAdmin.org.name') + $t('base.mandatory')"
              />
              <v-text-field
                v-model="tempOrg.shortname"
                readonly
                :label="$t('accAdmin.org.shortname')"
                :hint="$t('accAdmin.org.shortnameHint')"
                persistent-hint
              />
              <v-text-field
                v-model="tempOrg.uid"
                :label="$t('accAdmin.org.uid')"
                readonly
              />
              <!--<v-btn
                x-small
                @click.native='proposeShortname()'
              >{{ $t('accAdmin.orgList.proposeShortname') }}</v-btn>-->
            </v-col>
            <!--<v-col class="text-left">
              <v-text-field
              v-model="tempOrg.vatId"
              :label="$t('accAdmin.org.vatId')"
              :hint="$t('accAdmin.org.vatIdHint')"
            />
            </v-col>-->
          </v-row>
          <v-row>
            <v-col class="py-4">
              <v-label>{{$t('accAdmin.org.logo')}}</v-label>
              <v-row
                no-gutters
                class="pt-4 align-center cropperContainer"
              >
                <!-- Vorschau -->
                <v-img
                  v-if="logoBin && !editLogo"
                  width="240px"
                  max-width="240px"
                  height="60px"
                  class="ml-6"
                  :src="logoBin"
                />
                <div v-show="logoEditable">
                  <preview
                    v-if="editLogo"
                    class="ml-6"
                    :width="240"
                    :height="60"
                    :image="result.image"
                    :coordinates="result.coordinates"
                    style=""
                  />
                  <!-- Auswählen-Button -->
                  <v-btn
                    class=" secondary--text ml-8"
                    icon
                    @click="$refs.file.click()"
                    v-tooltip="$tooltip($t('acc.user.selectLogo'))"
                  >
                    <v-icon class="large">mdi-upload</v-icon>
                  </v-btn>
                  <input
                    hidden
                    type="file"
                    ref="file"
                    @change="loadLogoImage($event)"
                    accept="image/*"
                  >
                  <v-btn
                    v-if="editLogo"
                    class="ml-8 secondary--text"
                    icon
                    @click="resetLogoEdit"
                    v-tooltip="$tooltip($t('base.reset'))"
                  >
                    <v-icon class="large">mdi-close</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="tempOrg.logoBin != null  && !editLogo"
                    class="ml-8 secondary--text"
                    icon
                    @click="deleteLogo"
                    v-tooltip="$tooltip($t('acc.user.deleteLogo'))"
                  >
                    <v-icon class="large">mdi-delete-forever</v-icon>
                  </v-btn>
                </div>
              </v-row>
              <!-- Cropper -->
              <v-row
                no-gutters
                class="mt-4"
                style="max-height: 600px;"
              >
                <cropper
                  v-show="editLogo"
                  class="cropper"
                  background-class="cropper__background"
                  foreground-class="cropper__foreground"
                  ref="cropper"
                  :src="image.src"
                  :stencilComponent="$options.components.RectangleStencil"
                  :debounce="100"
                  :stencil-props="{
                  handlers: {},
                  movable: false,
                  scalable: false,
                  previewClass: 'cropper__stencil',
                }"
                  :stencil-size="{
                  width: 360,
                  height: 90
                }"
                  image-restriction="none"
                  @change="changeLogoPreview"
                />
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          md="6"
          sm="12"
          xs="12"
        >
          <v-row>
            <v-col>
              <v-text-field
                v-model="tempOrg.address1"
                :label="$t('accAdmin.org.address1')"
              />
              <v-text-field
                v-model="tempOrg.address2"
                :label="$t('accAdmin.org.address2')"
              />
              <v-row no-gutters>
                <v-col class="pr-3">
                  <v-text-field
                    v-model="tempOrg.zipCode"
                    :label="$t('accAdmin.org.zipCode')"
                  ></v-text-field>
                </v-col>
                <v-col class="pl-3">
                  <v-text-field
                    v-model="tempOrg.city"
                    :label="$t('accAdmin.org.city')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="pr-3">
                  <v-text-field
                    v-model="tempOrg.region"
                    :label="$t('accAdmin.org.region')"
                  ></v-text-field>
                </v-col>
                <v-col class="pl-3">
                  <v-text-field
                    v-model="tempOrg.country"
                    :label="$t('accAdmin.org.country')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="orgSettings.countrySettings.show === true">
                <v-col class="pr-3">
                </v-col>
                <v-col class="pl-3 pt-8">
                  <v-select
                    :label="$t('accAdmin.org.countryKey')"
                    :value="orgSettings.countrySettings.countryKey"
                    :items="countrySettingsArray"
                    item-value="key"
                    item-text="name"
                    @change="countrySettingsChanged"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- save or cancel editing -->
    <v-card-text>
      <SaveCancelButtonBar
        class="mt-4"
        :cancel-text="$t('base.back')"
        @cancel="$emit('cancel')"
        @save="save()"
      />
    </v-card-text>
  </v-card>
</template>

<script lang="js">
import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';
import { S3Helper } from '@/base/js/aws/S3Helper';
import { ImageHelper } from '@/base/js/ImageHelper';
import { countrySettings } from '@/base/js/baseHelper';

import { isProcessStateOK } from '@/base/js/ProcessDataHelper';
import { accHelper } from '@/acc/js/accHelper';
import { shortnameCheck } from '@/accAdmin/js/accAdminHelper';
import { Cropper, RectangleStencil, Preview } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  name: 'OrgAccount',
  components: {
    SaveCancelButtonBar,
    Cropper,
    // eslint-disable-next-line vue/no-unused-components
    RectangleStencil,
    Preview
  },
  props: {
    targetOrg: { type: Object, required: true },
    parentOrgUid: { type: String, required: true },
    logoEditable: { type: Boolean, default: true },
    orgSettings: { type: Object, required: true },
    logo: { type: String, required: true }
  },
  data () {
    return {
      tempOrg: accHelper.copyOrganisation(this.targetOrg),
      logoBin: null,
      editLogo: false,
      removeLogo: false,
      result: {
        coordinates: null,
        image: null
      },
      image: {
        src: null,
        type: null
      },
      rotation: 0
    };
  },
  computed: {
    countrySettingsArray () {
      return countrySettings;
    }
  },
  watch: {
    targetOrg () {
      this.tempOrg = accHelper.copyOrganisation(this.targetOrg);
      this.editLogo = false;
      this.getLogo();
    }
  },
  mounted () {
    if (this.targetOrg) {
      this.tempOrg = accHelper.copyOrganisation(this.targetOrg);
      this.editLogo = false;
      this.getLogo();
    }
  },
  methods: {
    changeLogoPreview ({ coordinates, image }) {
      this.result = {
        coordinates,
        image
      };
    },
    getMimeType (file, fallback = null) {
      const byteArray = (new Uint8Array(file)).subarray(0, 4);
      let header = '';
      for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16);
      }
      switch (header) {
        case '89504e47':
          return 'image/png';
        case '47494638':
          return 'image/gif';
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
          return 'image/jpeg';
        default:
          return fallback;
      }
    },
    loadLogoImage (event) {
      // Reference to the DOM input element
      const { files } = event.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0]);
        // 3. The steps below are designated to determine a file mime type to use it during the
        // getting of a cropped image from the canvas. You can replace it them by the following string,
        // but the type will be derived from the extension and it can lead to an incorrect result:
        //
        // this.image = {
        //    src: blob;
        //    type: files[0].type
        // }
        // Create a new FileReader to read this image binary data
        const reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.image" refers to the image of Vue component
          this.image = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: this.getMimeType(e.target.result, files[0].type)
          };
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(files[0]);
        this.editLogo = true;
        this.removeLogo = false;
      }
    },
    resetLogoEdit () {
      this.editLogo = false;
      this.result = { coordinates: null, image: null };
      this.image = { src: null, type: null };
    },
    deleteLogo () {
      this.resetLogoEdit();
      this.logoBin = null;
      this.tempOrg.logo = '-';
      this.tempOrg.logoBin = null;
      this.removeLogo = true;
    },
    emailChanged (email, verified) {
      this.tempOrg.email = email;
      this.tempOrg.emailVerified = verified;
      this.$emit('userUpdated', this.tempOrg);
    },
    mobileChanged (number, verified) {
      this.tempOrg.mobile = number;
      this.tempOrg.mobileVerified = verified;
      this.$emit('userUpdated', this.tempOrg);
    },
    async uploadLogo () {
      if (this.logoEditable !== true) {
        return;
      }
      let assets = this.$configHelper.getModuleConfigParam('acc', 'assets');
      let path = assets.pathOrgLogo + this.tempOrg.shortname;
      let { canvas } = this.$refs.cropper.getResult();
      this.logoBin = canvas.toDataURL(); // await ImageHelper.resizeBase64Image(canvas.toDataURL(), 240, 60);
      if (this.logoBin != null && this.logoBin.length > 0) {
        await S3Helper.uploadBase64Object(path, this.logoBin, assets.s3config, true);
        this.tempOrg.logoBin = this.logoBin;
        this.tempOrg.logo = path + '?' + Date.now();
        this.$logger.debug('accAdmin', 'Uploaded new logo');
      }
    },
    async deleteUploadedLogo () {
      if (this.logoEditable !== true) {
        return;
      }
      let assets = this.$configHelper.getModuleConfigParam('acc', 'assets');
      let path = assets.pathOrgLogo + this.logo;
      await S3Helper.deleteObject(path, assets.s3config);
      this.tempOrg.logoBin = null;
      this.tempOrg.logo = '-';
      this.logoBin = null;
    },

    async getLogo () {
      const assets = this.$configHelper.getModuleConfigParam('acc', 'assets');
      const imageurl = assets.url + assets.pathOrgLogo + this.logo;
      ImageHelper.getImage(imageurl)
        .then((logoBin) => {
          this.logoBin = logoBin;
        });
    },
    // async uploadLogo () {
    //   const assets = this.$configHelper.getModuleConfigParam('acc', 'assets');
    //   S3Helper.uploadBase64Object(assets.pathOrgLogo + this.targetOrg.shortname, this.logoBin, assets.s3config)
    //     .then((success) => {
    //       this.tempOrg.logoBin = this.logoBin;
    //       this.$logger.debug('accAdmin', 'Uploaded new logoBin');
    //       if (this.$store.state.base.org.uid === this.tempOrg.uid) {
    //         // update current org
    //         this.$store.state.base.org = accHelper.copyOrganisation(this.tempOrg);
    //       }
    //       this.$emit('logoUpdated', this.tempOrg.shortname);
    //     });
    // },
    countrySettingsChanged (country) {
      this.$emit('countrySettingsChanged', country);
    },
    async save () {
      // verify data
      let messages = [];
      if (this.tempOrg.name.trim().length === 0) {
        messages.push({ type: 'userError', message: this.$t('accAdmin.orgList.messages.orgNameNotSet') });
      }
      if (this.tempOrg.shortname.trim().length === 0) {
        messages.push({ type: 'userError', message: this.$t('accAdmin.orgList.messages.orgShortnameNotSet') });
      }

      if (messages.length > 0) {
        this.$globals.Info.open(messages);
      } else {
        try {
          this.$globals.Loader.show();

          if (this.editLogo) {
            await this.uploadLogo();
          } else if (this.removeLogo) {
            await this.deleteUploadedLogo();
          }
          // send data to backend
          let ioData = {
            parentOrg: this.parentOrgUid,
            targetOrg: {
              uid: this.tempOrg.uid,
              shortname: this.tempOrg.shortname,
              name: this.tempOrg.name,
              // vatId: this.tempOrg.vatId,
              address1: this.tempOrg.address1,
              address2: this.tempOrg.address2,
              zipCode: this.tempOrg.zipCode,
              city: this.tempOrg.city,
              region: this.tempOrg.region,
              country: this.tempOrg.country
            }
          };
          this.$restClient.callProcess('accAdmin', 'organisationUpdate', ioData, true).then((processData) => {
            if (isProcessStateOK(processData)) {
              this.$emit('orgUpdated', this.tempOrg);
              if (this.editLogo) this.$emit('logoUpdated', this.tempOrg.shortname);
              this.editLogo = false;
              this.removeLogo = false;
              if (processData.processState.state === 'warning') {
                this.$globals.Info.open(processData.processState.messages);
              }
              // this.$globals.Confirm.okDlg(this.$t('accAdmin.org.updateData'), this.$t('accAdmin.org.updateSuccess'));
              this.$logger.debug('accAdmin', 'Saved Org Account Data');
            } else {
              this.$globals.Info.open(processData.processState.messages);
            }
          });
        } finally {
          this.$globals.Loader.hide();
        }
      }
    },
    async proposeShortname () {
      shortnameCheck(this.tempOrg.name, this.tempOrg.shortname).then((sn) => {
        this.tempOrg.shortname = sn;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
