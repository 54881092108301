<template>
  <v-container class="page">
    <Headline
      :title="headlineText"
      icon="mdi-account-multiple"
    />
    <router-view
      @setPageMode="setPageMode"
      @cancel="headlineText = $t('accAdmin.userList.headline')"
    ></router-view>
  </v-container>
</template>

<script lang="js">
import Headline from '@/common/Headline.vue';

export default {
  name: 'Users',
  components: {
    Headline
  },
  data () {
    return {
      pageMode: 'table', // values: table, edit, invite
      headlineText: this.$t('accAdmin.userList.headline')
    };
  },
  methods: {
    setPageMode (mode, headlineParam = '') {
      this.pageMode = mode;
      switch (mode) {
        case 'create':
          this.headlineText = this.$t('accAdmin.userList.userCreate');
          break;
        case 'edit':
          this.headlineText = headlineParam;
          break;
        default: // 'table':
          this.headlineText = this.$t('accAdmin.userList.headline');
          break;
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
