<template>
  <v-container class="page">
    <Headline
      :title="headlineText"
      icon="mdi-folder-multiple-outline"
    />
    <router-view
      @setPageMode="setPageMode"
      @cancel="headlineText = $t('accAdmin.orgList.headline')"
    ></router-view>
  </v-container>
</template>

<script lang="js">
import Headline from '@/common/Headline';

export default {
  name: 'Organisations',
  components: {
    Headline
  },
  data () {
    return {
      pageMode: 'table', // values: table, edit, create
      headlineText: this.$t('accAdmin.orgList.headline')
    };
  },
  methods: {
    setPageMode (mode, headlineParam = '') {
      this.pageMode = mode;
      switch (mode) {
        case 'create':
          this.headlineText = this.$t('accAdmin.orgList.orgCreate');
          break;
        case 'edit':
          this.headlineText = headlineParam;
          break;
        default: // 'table':
          this.headlineText = this.$t('accAdmin.orgList.headline');
          break;
      }
    }
  }
};
</script>

<style scoped>
</style>
