import Vue from 'vue';

import { isProcessStateOK } from '@/base/js/ProcessDataHelper';

export const shortnameCheck = async (name, shortname = '') => {
  // verify data
  let messages = [];
  if (name.trim().length === 0) {
    messages.push({ type: 'userError', message: Vue.prototype.$t('accAdmin.orgList.messages.orgNameNotSet') });
  }
  if (shortname.trim().length === 0) {
    messages.push({ type: 'userError', message: Vue.prototype.$t('accAdmin.orgList.messages.orgShortnameNotSet') });
  }

  if (messages.length > 0) {
    Vue.prototype.$globals.Info.open(messages);
  } else {
    // send data to backend
    let ioData = {
      orgname: name,
      shortname: shortname
    };

    return new Promise(async (resolve) => {
      Vue.prototype.$restClient.callProcess('accAdmin', 'organisationShortnameCheck', ioData).then(
        (processData) => {
          let proposals = [];
          if (isProcessStateOK(processData)) {
            if (processData.processState.state === 'warning') {
              shortname = '';
              Vue.prototype.$globals.Info.open(processData.processState.messages);
              if (typeof processData.ioData.shortnameProposals !== 'undefined') {
                proposals = processData.ioData.shortnameProposals;
                if (proposals.length > 0) {
                  shortname = proposals[0];
                }
              }
              Vue.prototype.$logger.debug('accAdmin', 'shortname is not OK!');
            } else {
              // alles ok
              Vue.prototype.$logger.debug('accAdmin', 'shortname is OK!');
            }
          } else {
            Vue.prototype.$globals.Info.open(processData.processState.messages);
          }
          resolve([shortname, proposals]);
        }
      );
    });
  }
};
