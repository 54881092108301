<template>
  <v-card>
    <!--<v-card-title class="primary-bg">
      {{ $t("accAdmin.orgList.orgCreate") }}
    </v-card-title>-->
    <v-card-text class="px-0 pt-3">
      <v-row no-gutters>
        <v-col
          cols="12"
          md="4"
          sm="12"
        >
          <v-text-field
            v-model="targetOrg.name"
            :label="$t('accAdmin.org.name') + $t('base.mandatory')"
          />
          <v-text-field
            v-model="targetOrg.shortname"
            :label="$t('accAdmin.org.shortname') + $t('base.mandatory')"
            :hint="$t('accAdmin.org.shortnameHint')"
            persistent-hint
          />
          <v-btn
            :disabled="targetOrg.name.trim().length === 0"
            x-small
            @click.native='checkShortname(false)'
            class="mx-4 mb-2 mt-2 prio2"
          >{{ $t('accAdmin.orgList.checkShortname')}}</v-btn>
          <!--<v-text-field
              v-model="targetOrg.vatId"
              :label="$t('accAdmin.org.vatId')"
              :hint="$t('accAdmin.org.vatIdHint')"
            />-->
          <v-text-field
            v-if="$store.state.base.admin.administratedOrg.type === 'Root'"
            v-model="targetOrg.uid"
            :label="$t('accAdmin.org.uid')"
            :hint="$t('accAdmin.org.uidHint')"
          />
        </v-col>
        <v-col
          md="8"
          sm="12"
        >
          <v-row>
            <v-col>
              <v-text-field
                v-model="targetOrg.address1"
                :label="$t('accAdmin.org.address1')"
              />
              <v-text-field
                v-model="targetOrg.address2"
                :label="$t('accAdmin.org.address2')"
              />
              <v-row no-gutters>
                <v-col class="pr-3">
                  <v-text-field
                    v-model="targetOrg.zipCode"
                    :label="$t('accAdmin.org.zipCode')"
                  ></v-text-field>
                </v-col>
                <v-col class="pl-3">
                  <v-text-field
                    v-model="targetOrg.city"
                    :label="$t('accAdmin.org.city')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="pr-3">
                  <v-text-field
                    v-model="targetOrg.region"
                    :label="$t('accAdmin.org.region')"
                  ></v-text-field>
                </v-col>
                <v-col class="pl-3">
                  <v-text-field
                    v-model="targetOrg.country"
                    :label="$t('accAdmin.org.country')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-select
            v-model="assignedModules"
            :items="availableModules"
            item-text="name"
            item-value="uid"
            attach
            chips
            :label="$t('accAdmin.modules')"
            multiple
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="px-4">
          {{ $t('base.mandatoryHint') }}
        </v-col>
      </v-row>
    </v-card-text>
    <!-- save or cancel editing -->
    <v-card-text>
      <SaveCancelButtonBar
        class="mt-4"
        :saveText="$t('accAdmin.orgList.orgCreate')"
        @cancel="cancel()"
        @save="create()"
      />
    </v-card-text>
  </v-card>
</template>

<script lang="js">
import { loadJsonFromUrl } from '@/base/js/JsonLoader';
import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';
// import { shortnameCheck } from '@/accAdmin/js/accAdminHelper';
import { accHelper } from '@/acc/js/accHelper';
import { inheritOrgSettings, setAdministratedOrgList } from '@/baseAdmin/js/baseAdminHelper';
import { S3Helper } from '@/base/js/aws/S3Helper';

export default {
  name: 'OrgCreate',
  components: {
    SaveCancelButtonBar
  },
  props: {
    parentOrgUid: { type: String, default: null },
    parentOrgShortname: { type: String, default: null }
  },
  data () {
    return {
      targetOrg: accHelper.copyOrganisation(),
      proposals: [],
      availableModules: [],
      assignedModules: []
    };
  },
  computed: {
  },
  mounted () {
    if (this.parentOrgUid) {
      this.$emit('setPageMode', 'create');
      this.getAvailableModules();
    } else {
      this.$router.push('/accAdmin/accounts');
    }
  },
  methods: {
    getAvailableModules () {
      let list = [];
      for (let moduleUid of this.$configHelper.getBaseModules()) {
        if (this.$configHelper.getConfigParam('defaultModules').includes(moduleUid)) {
          this.assignedModules.push(moduleUid);
        } else {
          list.push({ uid: moduleUid, name: this.$t('base.moduleList.' + moduleUid) });
        }
      }
      this.availableModules = list;
    },
    async checkShortname (showMsg = true) {
      let ok = true;
      let sn = await this.shortnameCheck(this.targetOrg.name, this.targetOrg.shortname);
      if (this.targetOrg.shortname !== sn) {
        ok = false;
        if (showMsg) this.$globals.Info.open([{ type: 'userError', message: this.$t('accAdmin.orgList.messages.shortnameAlreadyInUse') }]);
      }
      this.targetOrg.shortname = sn;
      return ok;
    },
    /* Check if string is valid UUID */
    isValidUUID (str) {
      // Regular expression to check if string is a valid UUID
      let regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
      return regexExp.test(str);
    },
    async create () {
      // verify data
      let messages = [];
      if (this.targetOrg.name.trim().length === 0) {
        messages.push({ type: 'userError', message: this.$t('accAdmin.orgList.messages.orgNameNotSet') });
      }
      if (this.targetOrg.shortname.trim().length === 0) {
        messages.push({ type: 'userError', message: this.$t('accAdmin.orgList.messages.orgShortnameNotSet') });
      }
      if (this.targetOrg.uid != null && this.targetOrg.uid.length > 0) {
        this.targetOrg.uid = this.targetOrg.uid.toLowerCase();
        if (!this.isValidUUID(this.targetOrg.uid)) {
          messages.push({ type: 'userError', message: this.$t('accAdmin.orgList.messages.orgUidInvalidForm') });
        }
      } else {
        this.targetOrg.uid = null;
      }

      if (messages.length > 0) {
        this.$globals.Info.open(messages);
      } else if (await this.checkShortname()) {
        // send data to backend
        if (this.targetOrg.uid == null) delete this.targetOrg.uid;
        delete this.targetOrg.userCount;
        this.targetOrg.uidType = this.$sessionHelper.getOrgType();
        this.targetOrg.type = 'Consumer';
        this.targetOrg.isActive = true;
        let ioData = {
          parentOrg: this.parentOrgUid,
          targetOrg: this.targetOrg,
          modules: this.assignedModules
        };
        this.$logger.debug('accAdmin', 'Create new Org:', JSON.stringify(ioData));

        this.$restClient.callProcess('accAdmin', 'organisationCreate', ioData, true).then(async (processData) => {
          this.$globals.Info.open(processData.processState.messages);
          if (isProcessStateOK(processData)) {
            this.targetOrg.uid = processData.ioData.targetOrg.uid;
            let assets = this.$configHelper.getModuleConfigParam('acc', 'assets');
            S3Helper.copyObject(
              assets.pathOrgBaseSettings + this.parentOrgShortname + '.json',
              assets.pathOrgBaseSettings + this.targetOrg.shortname + '.json',
              assets.s3config
            );
            await inheritOrgSettings(this.parentOrgUid, this.targetOrg.uid, this.parentOrgShortname, this.targetOrg.shortname);

            this.addOrgToList();

            this.$logger.debug('accAdmin', 'new Org created!');
            this.$router.push('/accAdmin/accounts/' + this.targetOrg.uid);
          }
        });
      }
    },
    addOrgToList () {
      let parentOrgIndex = this.$store.state.base.admin.administratableOrgs.findIndex((org) => org.uid === this.parentOrgUid);
      let parentOrg = this.$store.state.base.admin.administratableOrgs[parentOrgIndex];
      console.log('addOrgToList', parentOrgIndex, JSON.stringify(parentOrg));
      // update parent org
      parentOrg.expandable = true;
      parentOrg.expanded = true;
      // set treeParams for new org
      this.targetOrg.treeParams = {
        show: true,
        expandable: false,
        expanded: false,
        treestring: parentOrg.treeParams.treestring + this.targetOrg.shortname + '_',
        parent: parentOrg.name,
        parentShort: parentOrg.shortname,
        parentId: this.parentOrgUid,
        depth: parentOrg.treeParams.depth + 1
      };
      // insert new org in flattened tree
      console.log('addOrgToList', JSON.stringify(this.targetOrg));
      this.$store.state.base.admin.administratableOrgs.splice(parentOrgIndex + 1, 0, this.targetOrg);
      setAdministratedOrgList(this.$store.state.base.admin.administratedOrg, this.$store.state.base.admin.administratableOrgs);
    },
    cancel () {
      // this.$emit('cancel');
      this.$router.back();
    },
    async shortnameCheck (name, shortname = '') {
      // if shortname set use it, else use modified name
      if (shortname.trim().length === 0) {
        shortname = name.trim();
      }
      shortname = shortname.toLowerCase().replace(/[^a-z0-9_]/g, '');

      let assets = this.$configHelper.getModuleConfigParam('acc', 'assets');
      let test = await loadJsonFromUrl(assets.url + assets.pathOrgBaseSettings + shortname + '.json');

      if (test == null) {
        return shortname;
      } else {
        let index = 0;
        while (test != null) {
          index++;
          test = await loadJsonFromUrl(assets.url + assets.pathOrgBaseSettings + shortname + index + '.json');
        }
        return shortname + index;
      }
    }
  }
};
</script>

<style scoped>
.v-input {
  margin: 0px 16px !important;
}
</style>
