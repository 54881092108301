<template>
  <v-card class="minContent">
    <v-card-title>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :disabled="selected.length === 0"
            color="secondary"
          >
            <v-icon class="mr-2">mdi-menu-down</v-icon>
            {{ $t("base.actions") }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            @click="editUser()"
            :disabled="selected.length !== 1"
          >
            <v-icon class="mr-4">mdi-pencil</v-icon>
            {{ $t("accAdmin.userList.userEdit")}}
          </v-list-item>
          <v-list-item @click="setActiveUsers(true)">
            <v-icon class="mr-4">mdi-checkbox-multiple-marked</v-icon>
            {{ $t("accAdmin.userList.userSetActive")}}
          </v-list-item>
          <v-list-item @click="setActiveUsers(false)">
            <v-icon class="mr-4">mdi-checkbox-multiple-blank-outline</v-icon>
            {{ $t("accAdmin.userList.userSetInactive")}}
          </v-list-item>
          <v-list-item @click="removeUsers()">
            <v-icon class="mr-4">mdi-account-remove</v-icon>
            {{ $t("accAdmin.userList.userRemove")}}
          </v-list-item>
        </v-list>
      </v-menu>
      <span class="ml-8 body-1">
        {{ selected.length + " " + $t('base.of') + " " + userList.length + " " + $t('base.selected') }}
      </span>
      <v-spacer />
      <v-btn
        @click="inviteNewUser()"
        color="secondary"
        class="mr-8"
      >
        <v-icon class="mr-2">mdi-account-plus</v-icon>
        {{ $t("accAdmin.userList.invite") }}
      </v-btn>
      <v-icon
        @click="getUserList()"
        color="secondary"
        class="mr-8"
      >mdi-reload</v-icon>
      <v-menu
        offset-y
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            color="secondary"
          >mdi-format-columns</v-icon>
        </template>

        <v-list>
          <v-list-item
            v-for="(col, index) in availableHeaders"
            :key="index"
          >
            <v-checkbox
              v-model="activeListHeaders[index]"
              :label="col.text"
            ></v-checkbox>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>

    <v-card-text class="pt-4">
      <v-row no-gutters>
        <v-col cols="12">
          <v-data-table
            v-model="selected"
            :headers="listHeaders"
            :items="userList"
            :items-per-page="15"
            :footer-props="footerProps"
            item-key="uid"
            show-select
            fixed-header
            :sort-by="listSortKey"
            @click:row="clickedRow"
            :loading="$store.state.base.admin.loadingUsers"
          >
            <template v-slot:body.prepend="{}">
              <tr>
                <td></td>
                <td
                  v-for="(h, index) in listHeaders"
                  :key="index"
                >
                  <SearchField
                    v-if="h.value === 'salutation'"
                    :value="filter.salutation"
                    @filter="applyFilters($event, 'salutation')"
                  />
                  <SearchField
                    v-if="h.value === 'title'"
                    :value="filter.title"
                    @filter="applyFilters($event, 'title')"
                  />
                  <SearchField
                    v-if="h.value === 'surname'"
                    :value="filter.surname"
                    @filter="applyFilters($event, 'surname')"
                  />
                  <SearchField
                    v-if="h.value === 'firstname'"
                    :value="filter.firstname"
                    @filter="applyFilters($event, 'firstname')"
                  />
                  <SearchField
                    v-if="h.value === 'username'"
                    :value="filter.username"
                    @filter="applyFilters($event, 'username')"
                  />
                  <SearchField
                    v-if="h.value === 'email'"
                    :value="filter.email"
                    @filter="applyFilters($event, 'email')"
                  />
                  <v-icon
                    v-if="h.value === 'menu'"
                    @click="clearFilters()"
                  >mdi-magnify-minus</v-icon>
                </td>
              </tr>
            </template>
            <template v-slot:header.data-table-select="{ on, props }">
              <v-simple-checkbox
                color="secondary"
                v-bind="props"
                v-on="on"
              ></v-simple-checkbox>
            </template>
            <template v-slot:header.menu="{}">
            </template>
            <template v-slot:item.mainstate="{ item }">
              <v-chip
                :disabled="item.state === 'inactive'"
                :outlined="item.invitationState !== 'applied'"
                :class="item.invitationState !== 'applied' ? 'success' : ''"
              >
                {{ $t('accAdmin.userList.' + item.mainstate) }}
              </v-chip>
            </template>
            <template v-slot:item.menu="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="editUser(item)">
                    <v-icon class="mr-4">mdi-pencil</v-icon>
                    {{ $t("accAdmin.userList.userEdit")}}
                  </v-list-item>
                  <v-list-item
                    v-if="item.state === 'inactive'"
                    @click="setActiveUser(item)"
                  >
                    <v-icon class="mr-4">mdi-checkbox-marked</v-icon>
                    {{ $t("accAdmin.userList.userSetActive")}}
                  </v-list-item>
                  <v-list-item
                    v-if="item.state === 'active'"
                    @click="setActiveUser(item)"
                  >
                    <v-icon class="mr-4">mdi-checkbox-blank-outline</v-icon>
                    {{ $t("accAdmin.userList.userSetInactive")}}
                  </v-list-item>
                  <!--<v-list-item
                    v-if="item.invitationState !== 'applied'"
                    @click="reinviteUser(item)"
                  >
                    <v-icon class="mr-4">mdi-email-send</v-icon>
                    {{ $t("accAdmin.userList.reinvite")}}
                  </v-list-item>-->
                  <v-list-item @click="removeUser(item)">
                    <v-icon class="mr-4">mdi-account-remove</v-icon>
                    {{ $t("accAdmin.userList.userRemove")}}
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="js">
import { loadUserList } from '@/baseAdmin/js/baseAdminHelper';
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';
import { simpleFilter } from '@/base/js/ObjectHelper';
import SearchField from '@/common/SearchField';

export default {
  name: 'UserTable',
  components: {
    SearchField
  },
  data () {
    return {
      selected: [],
      filter: {
        salutation: '',
        title: '',
        surname: '',
        firstname: '',
        username: '',
        email: ''
      },
      listSortKey: 'surname',
      footerProps: {
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [5, 15, 30, 50, -1]
      },
      availableHeaders: [
        { text: this.$t('acc.user.salutation'), value: 'salutation' },
        { text: this.$t('acc.user.title'), value: 'title' },
        { text: this.$t('acc.user.surname'), value: 'surname' },
        { text: this.$t('acc.user.firstname'), value: 'firstname' },
        { text: this.$t('acc.user.username'), value: 'username' },
        { text: this.$t('acc.user.email'), value: 'email' },
        { text: this.$t('acc.user.emailVerified'), value: 'emailVerified' },
        { text: this.$t('acc.user.mobile'), value: 'mobile' },
        { text: this.$t('acc.user.mobileVerified'), value: 'mobileVerified' },
        { text: this.$t('acc.user.state'), value: 'mainstate' }
      ],
      activeListHeaders: [false, false, true, true, true, true, false, false, false, true]
    };
  },
  computed: {
    userList () {
      return simpleFilter(this.$store.state.base.admin.administratedOrgObjects.userList, this.filter);
    },
    listHeaders () {
      let h = [];
      for (let i = 0; i < this.activeListHeaders.length; i++) {
        if (this.activeListHeaders[i]) {
          h.push(this.availableHeaders[i]);
        }
      }
      h.push({ text: 'menu', value: 'menu', sortable: false });
      return h;
    }
  },
  mounted () {
    this.$emit('setPageMode', 'table');
    if (!this.$store.state.base.admin.loadingUsers &&
      (this.$store.state.base.admin.administratedOrgObjects.userList.length === 0 || this.$route.query.refresh === true)) {
      this.getUserList();
    }
  },
  methods: {
    async getUserList () {
      // ToDo: invoke only if really neccessary (use of isDirty-flag)
      loadUserList(this.$store.state.base.admin.administratedOrg.uid).then(
        () => {}
      ).catch(
        (messages) => {
          this.$globals.Info.open(messages);
        }
      );
    },
    async removeUsers () {
      if (await this.$globals.Confirm.yesNoDlg(this.$t('accAdmin.userList.userRemove'), this.$t('accAdmin.userList.usersDeleteConfirmText'))) {
        for (let user of this.selected) {
          let ioData = {
            targetOrg: {
              uid: this.$store.state.base.admin.administratedOrg.uid,
              shortname: this.$store.state.base.admin.administratedOrg.shortname
            },
            targetUser: {
              uid: user.uid,
              email: user.email,
              username: user.username
            }
          };
          this.$restClient.callProcess('accAdmin', 'userRemoveFromOrganisation', ioData).then(
            (processData) => {
              if (isProcessStateOK(processData)) {
                this.$globals.Info.open([{ type: 'success', message: this.$t('accAdmin.userList.userRemoved', { user: user.firstname + ' ' + user.surname }) }]);
              } else {
                this.$globals.Info.open(processData.processState.messages);
              }
              this.$store.state.base.admin.administratedOrgObjects.userList.splice(
                this.$store.state.base.admin.administratedOrgObjects.userList.findIndex((u) => u.uid === user.uid), 1
              );
            }
          );
        }
      }
    },
    async removeUser (user) {
      // show confirmation dialog
      if (await this.$globals.Confirm.yesNoDlg(this.$t('accAdmin.userList.userRemove') + ': ' + user.firstname + ' ' + user.surname, this.$t('accAdmin.userList.userDeleteConfirmText'))) {
        let ioData = {
          targetOrg: {
            uid: this.$store.state.base.admin.administratedOrg.uid,
            shortname: this.$store.state.base.admin.administratedOrg.shortname
          },
          targetUser: {
            uid: user.uid,
            email: user.email,
            username: user.username
          }
        };
        this.$restClient.callProcess('accAdmin', 'userRemoveFromOrganisation', ioData).then(
          (processData) => {
            if (isProcessStateOK(processData)) {
              this.$globals.Info.open([{ type: 'success', message: this.$t('accAdmin.userList.userRemoved', { user: user.firstname + ' ' + user.surname }) }]);
            } else {
              this.$globals.Info.open(processData.processState.messages);
            }
            this.$store.state.base.admin.administratedOrgObjects.userList.splice(
              this.$store.state.base.admin.administratedOrgObjects.userList.findIndex((u) => u.uid === user.uid), 1
            );
            // this.$emit('reload');
          }
        );
      }
    },
    setActiveUsers (value) {
      let selectedUsers = [];
      for (let user of this.selected) {
        if ((user.state === 'active' && !value) || (user.state === 'inactive' && value)) {
          selectedUsers.push(user.uid);
          this.$logger.debug('accAdmin', 'Set Active-flag ' + value + ' for User ', user.username);
        }
      }
      if (selectedUsers.length > 0) {
        let ioData = {
          targetOrg: this.$store.state.base.admin.administratedOrg.uid,
          active: value,
          orgUsers: selectedUsers
        };
        this.$restClient.callProcess('accAdmin', 'organisationSetUsersState', ioData).then(
          (processData) => {
            if (isProcessStateOK(processData)) {
              for (let user of this.selected) {
                value ? user.state = 'active' : user.state = 'inactive';
                if (user.invitationState === 'applied') user.mainstate = user.state;
              }
            }
            this.$globals.Info.open(processData.processState.messages);
          }
        );
      }
    },
    setActiveUser (user) {
      // state can only be switched between active and inactive
      if (user.state === 'active' || user.state === 'inactive') {
        let targetState = false;
        if (user.state === 'inactive') targetState = true;

        let ioData = {
          targetOrg: this.$store.state.base.admin.administratedOrg.uid,
          active: targetState,
          orgUsers: [ user.uid ]
        };
        this.$restClient.callProcess('accAdmin', 'organisationSetUsersState', ioData).then(
          (processData) => {
            if (isProcessStateOK(processData)) {
              if (user.state === 'active') user.state = 'inactive';
              else user.state = 'active';
              if (user.invitationState === 'applied') user.mainstate = user.state;
            }
            this.$globals.Info.open(processData.processState.messages);
          }
        );
      }
    },
    inviteNewUser () {
      this.$router.push('/accAdmin/users/invite');
      // this.$emit('inviteUser');
    },
    reinviteUser (user) {
      this.$emit('reinvite', user);
    },
    editUser (user = null) {
      if (!user) {
        user = this.selected[0];
      }
      this.$router.push('/accAdmin/users/' + user.uid);
      // this.$emit('edit', user);
    },
    clickedRow (user) {
      this.editUser(user);
    },
    clearFilters () {
      this.filter.salutation = '';
      this.filter.title = '';
      this.filter.surname = '';
      this.filter.firstname = '';
      this.filter.username = '';
      this.filter.email = '';
      this.applyFilters();
    },
    applyFilters (value, filtername) {
      this.filter[filtername] = value;
      this.$logger.debug('accAdmin', 'applyFilters !!!', JSON.stringify(this.filter));
    }
  }
};
</script>

<style scoped>
</style>
