<template>
  <router-view v-if="isReady"></router-view>
</template>

<script lang="js">
export default {
  name: 'AccAdmin',
  computed: {
    isReady () {
      if (this.$store.state.base.admin.administratedOrg != null &&
          this.$i18n.keyExists('acc.packageId') &&
          this.$i18n.keyExists('accAdmin.packageId')) {
        this.$logger.debug('config', 'AccAdmin-module is ready');
        return true;
      }
      return false;
    }
  }
};
</script>
