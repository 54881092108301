<template>
  <v-card v-if="isInitialized === true">
    <v-tabs v-model="tab">
      <v-tab href="#profile">
        {{ $t('accAdmin.orgAccount') }}
      </v-tab>
      <v-tab href="#theme" v-if="isThemeEditable">
        {{ $t('accAdmin.appearance') }}
      </v-tab>
      <v-tab href="#settings"
        v-if="isRoot || (targetOrgUid !== $store.state.base.org.uid && orgSettings.moduleSettings === 'setByParent')">
        {{ $t('accAdmin.settings') }}
      </v-tab>
      <!--<v-tab href="#legal">
        {{ $t('accAdmin.legal') }}
      </v-tab>-->
      <v-tab href="#modules" v-if="isRoot && showModulesTab">
        {{ $t('accAdmin.modules') }}
      </v-tab>
      <v-tab v-if="customizedModuleSettingComponents.length > 0" href="#customProperties">
        {{ $t('accAdmin.customProperties') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" v-if="(orgSettings != null && orgBaseSettings != null)">
      <v-tab-item value="profile">
        <OrgAccount :targetOrg="targetOrg" :parentOrgUid="parentOrgUid" :logoEditable="isThemeEditable"
          :logo="orgBaseSettings.logo" :orgSettings="orgSettings" @countrySettingsChanged="countrySettingsChanged"
          @orgUpdated="orgUpdated" @logoUpdated="logoUpdated" @cancel="cancel" />
      </v-tab-item>
      <v-tab-item value="theme" v-if="isThemeEditable">
        <ThemeCreator :targetOrg="targetOrg" :themeKey="orgBaseSettings.theme" @themeUpdated="themeUpdated"
          @cancel="cancel" />
      </v-tab-item>
      <v-tab-item value="settings"
        v-if="isRoot || (targetOrgUid !== $store.state.base.org.uid && orgSettings.moduleSettings === 'setByParent')">
        <OrgSettings :targetOrgUid="targetOrgUid" :targetSettings="orgSettings" :parentOrgUid="parentOrgUid"
          :parentSettings="parentSettings" :isRoot="isRoot"
          @moduleSettingsChanged="showModulesTab = isRoot && $event !== 'inheritFromParent'" @update="saveOrgSettings"
          @cancel="cancel" />
      </v-tab-item>
      <!--<v-tab-item value="legal">
        <v-card>
          Hier steht nix
        </v-card>
      </v-tab-item>-->
      <v-tab-item value="modules" v-if="isRoot && showModulesTab">
        <OrgModules :targetOrgUid="targetOrgUid" :targetOrgShortName="targetOrg.shortname" :targetOrgModules="modules"
          :availableModules="availableModules" :moduleOrgSettings="moduleOrgSettings"
          @saveModuleOrgSettings="saveGlobalProps" @changed="modulesUpdated" @cancel="cancel" />
      </v-tab-item>
      <v-tab-item v-if="customizedModuleSettingComponents.length > 0" value="customProperties">
        <component v-for="item in customizedModuleSettingComponents" :key="item.moduleUid" class=""
          v-bind:is="item.component" :ref="item.moduleUid + 'CustomizedModuleSettings'" :targetOrgUid="targetOrgUid"
          :parentOrgUid="parentOrgUid" @saveModuleSettings="saveGlobalProps" @cancel="cancel"></component>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script lang="js">
import OrgAccount from '@/accAdmin/components/OrgAccount';
import ThemeCreator from '@/accAdmin/components/ThemeCreator';
import OrgModules from '@/accAdmin/components/OrgModules';
import OrgSettings from '@/accAdmin/components/OrgSettings';

import { loadJsonFromUrl } from '@/base/js/JsonLoader';
import { getAdministratableOrg, inheritOrgSettings } from '@/baseAdmin/js/baseAdminHelper';
import { completeSettings, orgSettingsTemplate } from '@/base/js/baseHelper';
import { isProcessStateOK, newMessage } from '@/base/js/ProcessDataHelper';
import { accHelper } from '@/acc/js/accHelper';
import { S3Helper } from '@/base/js/aws/S3Helper';

export default {
  name: 'OrgProfile',
  components: {
    OrgAccount,
    ThemeCreator,
    OrgModules,
    OrgSettings
  },
  props: {
    targetOrgUid: { type: String, required: true }
  },
  data () {
    return {
      isInitialized: false,
      targetOrg: {},
      modules: [],
      availableModules: [],
      moduleOrgSettings: {},
      parentModuleOrgSettings: {},
      customizedModuleSettingComponents: [],
      orgSettings: null,
      orgBaseSettings: null,
      parentSettings: null,
      parentOrgUid: null,
      parentOrgShortname: null,
      showModulesTab: false,
      orgSettingsDirty: false
    };
  },
  computed: {
    tab: {
      set (tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get () {
        return this.$route.query.tab;
      }
    },
    isRoot () {
      return this.$store.state.base.org.type === 'Root';
    },
    adminSettings () {
      return this.$store.state.base.orgSettings;
    },
    isThemeEditable () {
      if ((this.targetOrgUid !== this.$store.state.base.org.uid && this.orgSettings.theme.state !== 'inheritFromParent') ||
        this.isRoot ||
        this.orgSettings.theme.state === 'setInAccount'
      ) {
        return true;
      }
      return false;
    }
  },
  watch: {
    '$store.state.base.admin.administratedOrg': function () {
      this.init();
    },
    targetOrgUid () {
      this.init();
    }
  },
  async mounted () {
    if (!this.$store.state.base.admin.loadingOrgs) this.init();
  },
  methods: {
    async init () {
      this.isInitialized = false;
      this.$emit('setPageMode', 'edit');
      this.$globals.Loader.show();
      this.parentOrgUid = getAdministratableOrg(this.targetOrgUid).treeParams.parentId;
      this.parentOrgShortname = getAdministratableOrg(this.targetOrgUid).treeParams.parentShort;

      await Promise.all([
        this.loadOrg(),
        this.loadOrgSettings(),
        this.getAvailableModules(),
        this.getModuleSpecificOrgSettings(),
        this.getParentModuleSpecificOrgSettings()
      ]);
      await Promise.all([
        this.loadOrgBaseSettings(),
        this.loadCustomizedModuleSettingComponents()
      ]);
      if (this.isRoot && this.orgSettings.moduleSettings !== 'inheritFromParent') this.showModulesTab = true;
      this.$globals.Loader.hide();
      this.isInitialized = true;
    },
    async loadCustomizedModuleSettingComponents () {
      let cList = [];
      for (let mod of Object.keys(this.$store.state.base.admin.administratedOrgObjects.moduleSettings)) {
        let moduleUid = mod.replace('OrgSettings', '');
        let shortname = this.$store.state.base.admin.administratedOrgObjects.moduleSettings[mod].customShortname;
        if (this.$store.state.base.admin.administratedOrgObjects.moduleSettings[mod].useCustomizedModuleSettings === true &&
          (this.parentModuleOrgSettings != null && this.parentModuleOrgSettings[mod] != null && this.parentModuleOrgSettings[mod].passTroughDataToChildren === true)) {
          cList.push({
            moduleUid: mod.replace('OrgSettings', ''),
            component: () => import('../../.generic/accCustomDialogs/' + moduleUid + 'ModuleSettings_' + shortname + '.vue')
          });
        }
      }
      this.customizedModuleSettingComponents = cList;
    },
    async loadOrg () {
      const ioData = {
        targetOrg: this.targetOrgUid,
        rolesAndGroups: true
      };
      let processData = await this.$restClient.callProcess('accAdmin', 'organisationGet', ioData, true);
      if (isProcessStateOK(processData)) {
        this.targetOrg = processData.ioData.targetOrg;
        this.$emit('setPageMode', 'edit', this.targetOrg.name);
        this.modules = processData.ioData.availableModules;
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
    },
    orgUpdated (updatedOrg) {
      this.$logger.debug('accAdmin', 'Organisation updated');
      this.targetOrg = updatedOrg;
      if (this.$store.state.base.org.uid === updatedOrg.uid) {
        // update current org
        this.$store.state.base.org = accHelper.copyOrganisation(updatedOrg);
      }
      if (this.orgSettingsDirty) {
        this.saveOrgSettings(this.orgSettings);
      }
      let orgIndex = this.$store.state.base.admin.administratedOrgObjects.orgList.findIndex((org) => org.uid === updatedOrg.uid);
      updatedOrg.treeParams = this.$store.state.base.admin.administratedOrgObjects.orgList[orgIndex].treeParams;
      this.$store.state.base.admin.administratedOrgObjects.orgList[orgIndex] = updatedOrg;
      // this.$router.push('/accAdmin/accounts');
    },
    cancel () {
      this.$router.push('/accAdmin/accounts');
    },
    modulesUpdated (assignedModules) {
      this.modules = assignedModules;
    },
    // ***** OrgSettings ***** //
    async loadOrgBaseSettings () {
      let assets = this.$configHelper.getModuleConfigParam('acc', 'assets');
      let orgBaseSettings = await loadJsonFromUrl(assets.url + assets.pathOrgBaseSettings + this.targetOrg.shortname + '.json');
      if (orgBaseSettings == null) {
        this.orgBaseSettings = await loadJsonFromUrl(assets.url + assets.pathOrgBaseSettings + this.parentOrgShortname + '.json');
      } else {
        this.orgBaseSettings = orgBaseSettings;
        // update orgSettings with more recent orgBaseSettings
        this.orgSettings.theme.themeKey = orgBaseSettings.theme;
        this.orgSettings.theme.logo = orgBaseSettings.logo;
        this.orgSettings.defaultContact = orgBaseSettings.contact;
      }
    },
    async loadOrgSettings () {
      let ioData = {
        targetKey: this.targetOrgUid,
        context: 'orgSettings'
      };
      let processData = await this.$restClient.callProcess('baseAdmin', 'globalPropertiesAdminGet', ioData, true);
      if (processData.processState.state === 'success') {
        this.orgSettings = completeSettings(orgSettingsTemplate, processData.ioData.globalProps);
        if (processData.ioData.parentProps != null) {
          this.parentSettings = completeSettings(orgSettingsTemplate, processData.ioData.parentProps);
        }
      } else if (processData.processState.state === 'warning' &&
        processData.processState.messages[0].message === 'baseAdmin.globalProps.noResults') {
        // if orgSettings are not existing, clone from parent
        this.orgSettings = await inheritOrgSettings(this.parentOrgUid, this.targetOrg.uid, this.parentOrgShortname, this.targetOrg.shortname);
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
    },
    async getAvailableModules () {
      let list = [];
      for (let moduleUid of this.$configHelper.getBaseModules()) {
        let mod = {
          uid: moduleUid,
          default: this.$configHelper.getConfigParam('defaultModules').includes(moduleUid)
        };

        if (this.$configHelper.getModuleConfigParam(moduleUid, 'hasOrgSettingsComponent') === true) {
          mod.ref = moduleUid + 'OrgSettings';
          mod.settingsComponent = () => import('../../.generic/accCustomDialogs/' + moduleUid + 'OrgSettings.vue');
        }

        if (this.$configHelper.getModuleConfigParam(moduleUid, 'hasAdminPart') === true) {
          mod.adminModuleUid = moduleUid + 'Admin';
          if (this.$configHelper.getModuleConfigParam(mod.adminModuleUid, 'hasOrgSettingsComponent') === true) {
            mod.adminRef = moduleUid + 'AdminOrgSettings';
            mod.adminSettingsComponent = () => import('../../.generic/accCustomDialogs/' + moduleUid + 'AdminOrgSettings.vue');
          }
        }
        list.push(mod);
      }
      this.availableModules = list;
    },
    async getModuleSpecificOrgSettings () {
      let ioData = {
        targetKey: this.targetOrgUid,
        context: '',
        contextFilter: 'OrgSettings'
      };
      let processData = await this.$restClient.callProcess('baseAdmin', 'globalPropertiesGetList', ioData, true);
      if (isProcessStateOK(processData)) {
        this.moduleOrgSettings = processData.ioData.globalProps || {};
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
    },
    async getParentModuleSpecificOrgSettings () {
      if (this.targetOrgUid !== this.$store.state.base.org.uid) {
        let ioData = {
          targetKey: this.parentOrgUid,
          context: '',
          contextFilter: 'OrgSettings'
        };
        let processData = await this.$restClient.callProcess('baseAdmin', 'globalPropertiesGetList', ioData, true);
        if (isProcessStateOK(processData)) {
          this.parentModuleOrgSettings = processData.ioData.globalProps || {};
        } else {
          this.$globals.Info.open(processData.processState.messages);
        }
      }
    },
    countrySettingsChanged (countryKey) {
      this.orgSettings.countrySettings.countryKey = countryKey;
      this.orgSettingsDirty = true;
    },
    themeUpdated (themeKey) {
      this.orgSettings.theme.themeKey = themeKey;
      this.saveOrgSettings(this.orgSettings);
    },
    logoUpdated (logo) {
      this.orgSettings.theme.logo = logo;
      this.saveOrgSettings(this.orgSettings).then((success) => {
        if (success) this.$globals.Info.open([newMessage('success', 'accAdmin.org.logoUploaded')]);
      });
    },
    async saveOrgSettings (orgSettings) {
      let ioData = {
        targetKey: this.targetOrgUid,
        context: 'orgSettings',
        globalProps: orgSettings
      };

      let [gppd, obspd] = await Promise.all([
        this.$restClient.callProcess('baseAdmin', 'globalPropertiesUpdate', ioData, true),
        this.saveOrgBaseSettings(orgSettings)
      ]);
      if (gppd.processState.state === 'success' && obspd.processState.state === 'success') {
        this.orgSettingsDirty = false;
        this.orgSettings = gppd.ioData.globalProps;
        this.orgBaseSettings = obspd.ioData.orgBaseSettings;
        if (this.targetOrg.uid === this.$store.state.base.org.uid) {
          this.$store.state.base.orgBaseSettings = this.orgBaseSettings;
        }
        // todo: recursivley check all childOrgs to be updated
        return true;
      } else {
        if (gppd.processState.state !== 'success') this.$globals.Info.open(gppd.processState.messages);
        if (obspd.processState.state !== 'success') this.$globals.Info.open(obspd.processState.messages);
        return false;
      }
    },
    async saveOrgBaseSettings (orgSettings) {
      try {
        let orgBaseSettings = {
          theme: orgSettings.theme.themeKey,
          logo: orgSettings.theme.logo,
          contact: orgSettings.defaultContact
        };
        let assets = this.$configHelper.getModuleConfigParam('acc', 'assets');
        await S3Helper.uploadJson(
          assets.pathOrgBaseSettings + this.targetOrg.shortname + '.json',
          orgBaseSettings,
          assets.s3config,
          true
        );
        return ({ processState: { state: 'success' }, ioData: { orgBaseSettings: orgBaseSettings } });
      } catch (e) {
        return ({ processState: { state: 'systemError', messages: [{ type: 'systemError', message: e.message }] } });
      }
    },
    async saveGlobalProps (ioData) {
      this.$restClient.callProcess('baseAdmin', 'globalPropertiesCreate', ioData, true).then(async (processData) => {
        // We use create, because we don't know if Item is existing in DB (Create also works as update)
        if (isProcessStateOK(processData)) {
          this.moduleOrgSettings[ioData.context] = ioData.globalProps;
          if (this.targetOrgUid === this.$store.state.base.admin.administratedOrg.uid) {
            this.$store.state[ioData.moduleUid].orgSettings = ioData.globalProps; // update own settings
          }
        } else {
          this.$globals.Info.open(processData.processState.messages);
        }
      });
    }
    // ***** OrgSettings end ***** //
  }
};
</script>

<style scoped>
</style>
