<template>
  <v-card>
    <v-card-title class="primary-bg">
      {{ headline }}
    </v-card-title>
    <v-card-text class="px-0 pt-3 minContent">
      <v-row no-gutters>
        <v-col
          cols="12"
          md="3"
          sm="6"
        >
          <v-text-field
            v-model="salutation"
            :label="$t('acc.user.salutation')"
          />
        </v-col>
        <v-col
          md="3"
          sm="6"
        >
          <v-text-field
            v-model="title"
            :label="$t('acc.user.title')"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          sm="6"
          cols="12"
        >
          <v-text-field
            v-model="firstname"
            :label="$t('acc.user.firstname')"
          />
        </v-col>
        <v-col
          sm="6"
          cols="12"
        >
          <v-text-field
            v-model="surname"
            :label="$t('acc.user.surname')"
          />
        </v-col>
        <v-col
          sm="6"
          cols="12"
        >
          <v-text-field
            v-model="email"
            :label="$t('acc.user.email') + $t('base.mandatory')"
            persistent-hint
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          sm="6"
          cols="12"
        >
          <v-select
            v-model="selectedRoles"
            :items="availableRoles"
            item-text="name"
            item-value="uid"
            attach
            chips
            :label="$t('accAdmin.roles')"
            multiple
          ></v-select>
        </v-col>
        <!--<v-col
          sm="6"
          cols="12"
        >
          <v-select
            v-model="selectedGroups"
            :items="availableGroups"
            item-text="name"
            item-value="uid"
            attach
            chips
            :label="$t('accAdmin.groups')"
            multiple
          ></v-select>
        </v-col>-->
      </v-row>
      <v-row no-gutters>
        <v-col class="px-4">
          <span>{{ $t('base.mandatoryHint') }}</span>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- save or cancel editing -->
    <v-card-text>
      <SaveCancelButtonBar
        class="mt-4"
        :saveText="$t('accAdmin.invite.invite')"
        @cancel="cancel()"
        @save="invite()"
      />
    </v-card-text>
  </v-card>
</template>

<script lang="js">
import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';
import { accHelper } from '@/acc/js/accHelper';

export default {
  name: 'UserInvite',
  components: {
    SaveCancelButtonBar
  },
  data () {
    return {
      email: '',
      salutation: '',
      title: '',
      surname: '',
      firstname: '',
      selectedRoles: [],
      selectedGroups: []
    };
  },
  computed: {
    availableGroups () {
      return this.$store.state.base.admin.administratedOrgObjects.groupList.filter(
        (g) => g.uid !== this.$store.state.base.admin.administratedOrgObjects.defaultGroup
      );
    },
    availableRoles () {
      let list = [];
      for (let role of this.$store.state.base.admin.administratedOrgObjects.roleList) {
        let moduleUid = role.uid.substring(0, role.uid.length - 7);
        if (this.$configHelper.getModuleConfigParam(moduleUid, 'hasAdminPart') === true) {
          list.push({ uid: role.uid, name: this.$t(moduleUid + 'Admin.userRights.roles.' + role.uid) });
        }
      }
      return list;
    },
    headline () {
      return this.$t('accAdmin.invite.headline').replace('###', this.$store.state.base.org.name);
    }
  },
  mounted () {
    if (this.$store.state.base.admin.administratedOrg.uid) {
      this.$emit('setPageMode', 'invite');
    } else {
      this.$router.push('/accAdmin/users');
    }
  },
  methods: {
    async invite () {
      // verify data
      let messages = [];
      if (this.email.trim().length === 0) {
        messages.push({ type: 'userError', message: this.$t('accAdmin.invite.messages.emailNotSet') });
      }

      if (messages.length > 0) {
        this.$globals.Info.open(messages);
      } else {
        let appRegistrationUrl = this.$store.state.base.admin.administratedOrgObjects.orgSettings.portalUrl;
        let assets = this.$configHelper.getModuleConfigParam('acc', 'assets');
        let orgLogoUrl = assets.url + assets.pathOrgLogo +
                          this.$store.state.base.admin.administratedOrgObjects.orgSettings.theme.logo;
        // send data to backend
        let ioData = {
          invitingUser: {
            surname: this.$store.state.base.user.surname,
            firstname: this.$store.state.base.user.firstname
          },
          targetUser: {
            uidType: this.$sessionHelper.getUserType(),
            salutation: this.salutation.trim(),
            title: this.title.trim(),
            surname: this.surname.trim(),
            firstname: this.firstname.trim(),
            email: this.email.trim().toLowerCase(),
            invitationState: 'invited'
          },
          roles: this.selectedRoles,
          userGroups: this.selectedGroups,
          targetOrg: {
            uid: this.$store.state.base.admin.administratedOrg.uid,
            shortname: this.$store.state.base.admin.administratedOrg.shortname,
            name: this.$store.state.base.admin.administratedOrg.name
          },
          appRegistrationUrl: appRegistrationUrl,
          appUrl: appRegistrationUrl,
          orgLogoS3Url: orgLogoUrl
        };
        // add default userGroup
        if (this.$store.state.base.admin.administratedOrgObjects.defaultGroup) {
          ioData.userGroups.push(this.$store.state.base.admin.administratedOrgObjects.defaultGroup);
        }

        this.$restClient.callProcess('accAdmin', 'userAddToOrganisation', ioData, true).then(
          (processData) => {
            if (isProcessStateOK(processData)) {
              this.$logger.debug('accAdmin', 'User add to organisation');
              this.$globals.Info.open([{ type: 'success', message: 'accAdmin.invite.success' }]);
              let newUser = accHelper.copyUser(processData.ioData.targetUser);
              newUser.mainstate = newUser.invitationState;
              this.$store.state.base.admin.administratedOrgObjects.userList.push(newUser);
              this.$router.push('/accAdmin/users/');
            } else {
              this.$globals.Info.open(processData.processState.messages);
            }
          }
        );
      }
    },
    cancel () {
      // this.$emit('cancel');
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.v-input {
  margin: 0px 16px !important;
}
</style>
