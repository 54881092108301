<template>
  <v-card>
    <v-card-text class="minContent">
      <v-row no-gutters>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="secondary"
              :disabled="selected.length === 0"
            >
              <v-icon class="mr-2">mdi-menu-down</v-icon>
              {{ $t("base.actions") }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="assignUsers(true)">
              <v-icon class="mr-4">mdi-checkbox-multiple-marked</v-icon>
              {{ $t("accAdmin.group.assignUsers")}}
            </v-list-item>
            <v-list-item @click="assignUsers(false)">
              <v-icon class="mr-4">mdi-checkbox-multiple-blank-outline</v-icon>
              {{ $t("accAdmin.group.removeUsers")}}
            </v-list-item>
          </v-list>
        </v-menu>
        <span class="ml-8 body-1">
          {{ selected.length + " " + $t('base.of') + " " + groupUserList.length + " " + $t('base.selected') }}
        </span>
        <v-spacer />
        <v-icon
          @click="loadGroupUsers()"
          color="secondary"
          class="mr-8"
        >mdi-reload</v-icon>
      </v-row>
      <v-row
        no-gutters
        class="mt-8"
      >
        <v-col cols="12">
          <v-data-table
            v-model="selected"
            :headers="listHeaders"
            :items="filteredGroupUserList"
            :items-per-page="15"
            :footer-props="footerProps"
            item-key="uid"
            show-select
            fixed-header
            :sort-by="listSortKey"
          >
            <template v-slot:body.prepend="{}">
              <tr>
                <td></td>
                <td
                  v-for="(h, index) in listHeaders"
                  :key="index"
                  class="caption"
                >
                  <SearchField
                    v-if="h.value === 'surname'"
                    :value="filter.surname"
                    @filter="applyFilters($event, 'surname')"
                  />
                  <SearchField
                    v-if="h.value === 'firstname'"
                    :value="filter.firstname"
                    @filter="applyFilters($event, 'firstname')"
                  />
                  <SearchField
                    v-if="h.value === 'email'"
                    :value="filter.email"
                    @filter="applyFilters($event, 'email')"
                  />
                  <v-icon
                    v-if="h.value === 'assigned'"
                    @click="clearFilters()"
                  >mdi-magnify-minus</v-icon>
                </td>
              </tr>
            </template>
            <template v-slot:header.data-table-select="{ on, props }">
              <v-simple-checkbox
                color="secondary"
                v-bind="props"
                v-on="on"
              ></v-simple-checkbox>
            </template>
            <template v-slot:item.assigned="{ item }">
              <v-switch
                color="secondary"
                v-model="item.assigned"
                @change="switchAssignment(item)"
                class="small-switch"
              />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- save or cancel editing -->
    <v-card-text>
      <SaveCancelButtonBar
        :cancel-text="$t('base.back')"
        @cancel="$router.back()"
        :showSave="false"
      />
    </v-card-text>
  </v-card>
</template>

<script lang="js">
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';
import { loadUserList } from '@/baseAdmin/js/baseAdminHelper';
import { simpleFilter } from '@/base/js/ObjectHelper';
import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';
import SearchField from '@/common/SearchField';

export default {
  name: 'GroupUserTable',
  components: {
    SaveCancelButtonBar,
    SearchField
  },
  props: {
    targetOrgUid: { type: String, required: true },
    targetGroupId: { type: String, required: true }
  },
  data () {
    return {
      groupUserList: [],
      groupUsers: [],
      selected: [],
      filter: {
        surname: '',
        firstname: '',
        email: ''
      },
      listSortKey: 'surname',
      footerProps: {
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [15, 30, 50, -1]
      },
      availableHeaders: [
        { text: this.$t('acc.user.surname'), value: 'surname' },
        { text: this.$t('acc.user.firstname'), value: 'firstname' },
        { text: this.$t('acc.user.email'), value: 'email' }
      ],
      activeListHeaders: [true, true, true]
    };
  },
  computed: {
    filteredGroupUserList () {
      return simpleFilter(this.groupUserList, this.filter);
    },
    listHeaders () {
      const h = [];
      for (let i = 0; i < this.activeListHeaders.length; i++) {
        if (this.activeListHeaders[i]) {
          h.push(this.availableHeaders[i]);
        }
      }
      h.push({ text: this.$t('base.assigned'), value: 'assigned' });
      return h;
    }
  },
  watch: {
    groupUsers () {
      this.prepareGroupUserAssignment();
    }
  },
  async mounted () {
    if (this.$store.state.base.admin.administratedOrgObjects.userList.length === 0) {
      await this.getUserList();
    }
    this.loadGroupUsers();
    this.prepareGroupUserAssignment();
  },
  methods: {
    async getUserList () {
      loadUserList(this.$store.state.base.admin.administratedOrg.uid).then(() => {
      }).catch(
        (messages) => {
          this.$globals.Info.open(messages);
        }
      );
    },
    async loadGroupUsers () {
      const ioData = {
        targetOrg: this.targetOrgUid,
        targetGroup: this.targetGroupId
      };
      this.$restClient.callProcess('accAdmin', 'userGroupGetUsers', ioData, true).then((processData) => {
        if (isProcessStateOK(processData)) {
          this.groupUsers = processData.ioData.groupUsers;
        }
        this.$globals.Info.open(processData.processState.messages);
      });
    },
    prepareGroupUserAssignment () {
      this.groupUserList = [];
      for (const user of this.$store.state.base.admin.administratedOrgObjects.userList) {
        let groupUser = {
          uid: user.uid,
          surname: user.surname,
          firstname: user.firstname,
          email: user.email,
          assigned: this.groupUsers.includes(user.uid)
        };
        this.groupUserList.push(groupUser);
      }
    },
    assignUsers (assign) {
      const selectedUsers = [];
      for (let u of this.selected) {
        if (u.assigned !== assign) selectedUsers.push(u.uid);
      }
      const ioData = {
        targetOrg: {
          uid: this.targetOrgUid
        },
        targetGroupList: [ this.targetGroupId ],
        assign: assign,
        targetUserList: selectedUsers
      };
      this.$restClient.callProcess('accAdmin', 'userGroupAssignUsers', ioData).then(
        (processData) => {
          if (isProcessStateOK(processData)) {
            for (let u of this.selected) {
              u.assigned = assign;
            }
            let change = selectedUsers.length;
            this.updateUserCount(this.targetGroupId, assign ? change : -(change));
          }
          this.$globals.Info.open(processData.processState.messages);
        }
      );
    },
    switchAssignment (user) {
      this.$logger.debug('accAdmin', 'switchAssignment', user);
      const ioData = {
        targetOrg: {
          uid: this.targetOrgUid
        },
        targetGroupList: [ this.targetGroupId ],
        assign: user.assigned,
        targetUserList: [ user.uid ]
      };
      this.$restClient.callProcess('accAdmin', 'userGroupAssignUsers', ioData).then(
        (processData) => {
          if (!isProcessStateOK(processData)) { // ACHTUNG!!! => not isProcessStateOk
            user.assigned = !user.assigned;
          } else {
            this.updateUserCount(this.targetGroupId, user.assigned ? 1 : -1);
          }
          this.$globals.Info.open(processData.processState.messages);
        }
      );
    },
    updateUserCount (groupUid, change) {
      let group = this.$store.state.base.admin.administratedOrgObjects.groupList.find((g) => g.uid === groupUid);
      if (group != null) {
        group.userCount += change;
      }
    },
    clearFilters () {
      this.filter.surname = '';
      this.filter.firstname = '';
      this.filter.email = '';
      this.applyFilters();
    },
    applyFilters (value, filtername) {
      this.filter[filtername] = value;
      this.$logger.debug('accAdmin', 'applyFilters', this.filter.name);
    }
  }
};
</script>

<style scoped>
</style>
