<template>
  <v-card>
    <v-card-text class="minContent">
      <v-row no-gutters>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              :disabled="selected.length === 0"
              color="secondary"
            >
              <v-icon class="mr-2">mdi-menu-down</v-icon>
              {{ $t("base.actions") }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              @click="editOrg()"
              :disabled="selected.length !== 1"
            >
              <v-icon class="mr-4">mdi-pencil</v-icon>
              {{ $t("accAdmin.orgList.orgEdit")}}
            </v-list-item>
            <v-list-item
              v-if="$store.state.base.orgSettings.subOrgSettings.createSubOrgs === true"
              @click="createSubOrg()"
              :disabled="selected.length !== 1"
            >
              <v-icon class="mr-4">mdi-folder-plus</v-icon>
              {{ $t("accAdmin.orgList.orgCreate")}}
            </v-list-item>
            <v-list-item @click="setActiveOrgs(true)">
              <v-icon class="mr-4">mdi-checkbox-multiple-marked</v-icon>
              {{ $t("accAdmin.orgList.orgSetActive")}}
            </v-list-item>
            <v-list-item @click="setActiveOrgs(false)">
              <v-icon class="mr-4">mdi-checkbox-multiple-blank-outline</v-icon>
              {{ $t("accAdmin.orgList.orgSetInactive")}}
            </v-list-item>
            <v-list-item @click="deleteOrgs()">
              <v-icon class="mr-4">mdi-folder-remove</v-icon>
              {{ $t("accAdmin.orgList.orgDelete")}}
            </v-list-item>
          </v-list>
        </v-menu>
        <span class="ml-8 body-1">
          {{ selected.length + " " + $t('base.of') + " " + flattenedTree.length + " " + $t('base.selected') }}
        </span>
        <v-spacer />
        <v-icon
          @click="loadOrgTree()"
          color="secondary"
          class="mr-8"
        >mdi-reload</v-icon>
        <v-icon
          v-if="!treeview"
          @click="treeview = true"
          v-tooltip="$tooltip($t('accAdmin.orgList.switchToTreeview'))"
          color="secondary"
          class="mr-8"
        >mdi-file-tree</v-icon>
        <v-icon
          v-if="treeview"
          @click="treeview = false"
          v-tooltip="$tooltip($t('accAdmin.orgList.switchToListview'))"
          color="secondary"
          class="mr-8"
        >mdi-format-list-checkbox</v-icon>

        <v-menu
          offset-y
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="secondary"
            >mdi-format-columns</v-icon>
          </template>

          <v-list>
            <v-list-item
              v-for="(col, index) in availableHeaders"
              :key="index"
            >
              <v-checkbox
                v-if="treeview"
                v-model="activeTreeHeaders[index]"
                :label="col.text"
                dense
              ></v-checkbox>
              <v-checkbox
                v-if="!treeview"
                v-model="activeListHeaders[index]"
                :label="col.text"
                dense
              ></v-checkbox>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
      <v-row
        no-gutters
        class="mt-8"
      >
        <v-col cols="12">
          <v-data-table
            v-if="treeview"
            v-model="selected"
            :headers="treeHeaders"
            :items="flattenedTree"
            :items-per-page="15"
            :footer-props="footerProps"
            item-key="uid"
            show-select
            fixed-header
            :loading="$store.state.base.admin.loadingOrgs"
            @click:row="clickedRow"
            :sort-desc="false"
            :sort-by="treeSortKey"
          >
            <template v-slot:header.data-table-select="{ on, props }">
              <v-simple-checkbox
                color="secondary"
                v-bind="props"
                v-on="on"
              ></v-simple-checkbox>
            </template>
            <template v-slot:header.userCount="{}">
              <v-icon class="primaryContrast--text text--lighten-3">mdi-account-multiple</v-icon>
            </template>
            <template v-slot:header.menu="{}">
            </template>
            <template v-slot:item.treeParams.show="{}">
            </template>
            <template
              v-slot:item.treeParams.treestring="{ item }"
              v-if="treeview"
            >
              <div v-bind:style="treePadding(item.treeParams.depth)">
                <v-menu v-if="item.treeParams.expandable">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      @click.native="expanderClicked(item)"
                    >
                      <v-icon
                        v-if="!item.treeParams.expanded"
                        color="primary"
                      >mdi-plus-circle-outline</v-icon>
                      <v-icon
                        v-if="item.treeParams.expanded"
                        color="primary"
                      >mdi-minus-circle-outline</v-icon>
                    </v-btn>
                  </template>
                </v-menu>
                <v-icon
                  v-if="!item.treeParams.expandable"
                  color="grey"
                  style="margin-left: 6px; margin-right: 6px;"
                >mdi-circle-outline</v-icon>
                {{ item.name }}
              </div>
            </template>
            <template v-slot:item.isActive="{ item }">
              <v-chip :disabled="!item.isActive">
                {{ item.isActive ? $t('accAdmin.org.active') : $t('accAdmin.org.inactive') }}
              </v-chip>
            </template>
            <template v-slot:item.menu="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="editOrg(item)">
                    <v-icon class="mr-4">mdi-pencil</v-icon>
                    {{ $t("accAdmin.orgList.orgEdit")}}
                  </v-list-item>
                  <v-list-item
                    v-if="$store.state.base.orgSettings.subOrgSettings.createSubOrgs === true"
                    @click="createSubOrg(item)"
                  >
                    <v-icon class="mr-4">mdi-folder-plus</v-icon>
                    {{ $t("accAdmin.orgList.orgCreate")}}
                  </v-list-item>
                  <v-list-item
                    v-if="item.uid !== $store.state.base.org.uid"
                    @click="setActiveOrg(item)"
                  >
                    <v-icon class="mr-4">{{ item.isActive ? 'mdi-checkbox-blank-outline' : 'mdi-checkbox-marked' }}</v-icon>
                    {{ item.isActive ? $t("accAdmin.orgList.orgSetInactive") : $t("accAdmin.orgList.orgSetActive") }}
                  </v-list-item>
                  <v-list-item
                    v-if="item.uid !== $store.state.base.org.uid"
                    @click="deleteOrg(item)"
                  >
                    <v-icon class="mr-4">mdi-folder-remove</v-icon>
                    {{ $t("accAdmin.orgList.orgDelete")}}
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>

          <v-data-table
            v-if="!treeview"
            v-model="selected"
            :headers="listHeaders"
            :items="filteredTree"
            :items-per-page="15"
            :footer-props="footerProps"
            item-key="uid"
            show-select
            fixed-header
            :sort-by="listSortKey"
            @click:row="clickedRow"
          >
            <template v-slot:body.prepend="{}">
              <tr>
                <td></td>
                <td
                  v-for="(h, index) in listHeaders"
                  :key="index"
                >
                  <SearchField
                    v-if="h.value === 'name'"
                    :value="filter.name"
                    @filter="applyFilters($event, 'name')"
                  />
                  <SearchField
                    v-if="h.value === 'address1'"
                    :value="filter.address1"
                    @filter="applyFilters($event, 'address1')"
                  />
                  <SearchField
                    v-if="h.value === 'address2'"
                    :value="filter.address2"
                    @filter="applyFilters($event, 'address2')"
                  />
                  <SearchField
                    v-if="h.value === 'zipCode'"
                    :value="filter.zipCode"
                    @filter="applyFilters($event, 'zipCode')"
                  />
                  <SearchField
                    v-if="h.value === 'city'"
                    :value="filter.city"
                    @filter="applyFilters($event, 'city')"
                  />
                  <SearchField
                    v-if="h.value === 'region'"
                    :value="filter.region"
                    @filter="applyFilters($event, 'region')"
                  />
                  <SearchField
                    v-if="h.value === 'country'"
                    :value="filter.country"
                    @filter="applyFilters($event, 'country')"
                  />
                  <SearchField
                    v-if="h.value === 'parent'"
                    :value="filter.parent"
                    @filter="applyFilters($event, 'parent')"
                  />
                  <v-icon
                    v-if="h.value === 'menu'"
                    @click="clearFilters()"
                  >mdi-magnify-minus</v-icon>
                </td>
              </tr>
            </template>
            <template v-slot:header.data-table-select="{ on, props }">
              <v-simple-checkbox
                color="secondary"
                v-bind="props"
                v-on="on"
              ></v-simple-checkbox>
            </template>
            <template v-slot:header.userCount="{}">
              <v-icon>mdi-account-multiple</v-icon>
            </template>
            <template v-slot:header.menu="{}">
            </template>
            <template v-slot:item.isActive="{ item }">
              <v-chip :disabled="!item.isActive">
                {{ item.isActive ? $t('accAdmin.org.active') : $t('accAdmin.org.inactive') }}
              </v-chip>
            </template>
            <template v-slot:item.menu="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="editOrg(item)">
                    <v-icon class="mr-4">mdi-pencil</v-icon>
                    {{ $t("accAdmin.orgList.orgEdit")}}
                  </v-list-item>
                  <v-list-item
                    v-if="$store.state.base.orgSettings.subOrgSettings.createSubOrgs === true"
                    @click="createSubOrg(item)"
                  >
                    <v-icon class="mr-4">mdi-folder-plus</v-icon>
                    {{ $t("accAdmin.orgList.orgCreate")}}
                  </v-list-item>
                  <v-list-item
                    v-if="item.uid !== $store.state.base.org.uid"
                    @click="setActiveOrg(item)"
                  >
                    <v-icon class="mr-4">{{ item.isActive ? 'mdi-checkbox-blank-outline' : 'mdi-checkbox-marked' }}</v-icon>
                    {{ item.isActive ? $t("accAdmin.orgList.orgSetInactive") : $t("accAdmin.orgList.orgSetActive") }}
                  </v-list-item>
                  <v-list-item
                    v-if="item.uid !== $store.state.base.org.uid"
                    @click="deleteOrg(item)"
                  >
                    <v-icon class="mr-4">mdi-folder-remove</v-icon>
                    {{ $t("accAdmin.orgList.orgDelete")}}
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="js">
import { reloadOrgList } from '@/baseAdmin/js/baseAdminHelper';
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';
import { simpleFilter } from '@/base/js/ObjectHelper';
import { S3Helper } from '@/base/js/aws/S3Helper';
import SearchField from '@/common/SearchField';

export default {
  name: 'OrgTreeTable',
  components: {
    SearchField
  },
  data () {
    return {
      treeview: true,
      selected: [],
      filter: {
        name: '',
        address1: '',
        address2: '',
        zipCode: '',
        city: '',
        region: '',
        country: '',
        parent: ''
      },
      listSortKey: 'name',
      treeSortKey: 'treestring',
      footerProps: {
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [15, 30, 50, -1]
      },
      availableHeaders: [
        { text: this.$t('accAdmin.org.address1'), value: 'address1' },
        { text: this.$t('accAdmin.org.address2'), value: 'address2', sortable: false },
        { text: this.$t('accAdmin.org.zipCode'), value: 'zipCode' },
        { text: this.$t('accAdmin.org.city'), value: 'city' },
        { text: this.$t('accAdmin.org.region'), value: 'region' },
        { text: this.$t('accAdmin.org.country'), value: 'country' },
        { text: this.$t('accAdmin.org.state'), value: 'isActive', width: '150px' },
        { text: this.$t('accAdmin.org.type'), value: 'type' },
        { text: this.$t('accAdmin.org.licences'), value: 'licences', sortable: false },
        { text: this.$t('accAdmin.org.userCount'), value: 'userCount' },
        { text: this.$t('accAdmin.org.parent'), value: 'treeParams.parent' }
      ],
      activeTreeHeaders: [false, false, true, true, false, false, true, false, true, true, false],
      activeListHeaders: [false, false, true, true, false, false, true, false, true, true, true]
    };
  },
  computed: {
    flattenedTree () {
      return this.$store.state.base.admin.administratedOrgObjects.orgList;
    },
    filteredTree () {
      return simpleFilter(this.$store.state.base.admin.administratedOrgObjects.orgList, this.filter);
    },
    treeHeaders () {
      const h = [];
      h.push({ width: '0', text: '', class: 'showColumn', value: 'treeParams.show', sortable: false, filter: (value) => { return value; } });
      h.push({ width: '300px', text: this.$t('accAdmin.org.name'), value: 'treeParams.treestring', sortable: false });
      if (this.$store.state.base.admin.administratedOrg.type === 'Root') {
        h.push({ class: 'error lighten-4', text: this.$t('accAdmin.org.uid'), value: 'uid' });
        h.push({ class: 'error lighten-4', text: this.$t('accAdmin.org.shortname'), value: 'shortname' });
      }
      for (let i = 0; i < this.activeTreeHeaders.length; i++) {
        if (this.activeTreeHeaders[i]) {
          const ah = {
            text: this.availableHeaders[i].text,
            value: this.availableHeaders[i].value,
            sortable: false,
            width: Number.isInteger(this.availableHeaders[i].width) ? this.availableHeaders[i].width : 'auto'
          };
          h.push(ah);
        }
      }
      h.push({ width: '40px', text: 'menu', value: 'menu', sortable: false });
      return h;
    },
    listHeaders () {
      const h = [];
      h.push({ text: this.$t('accAdmin.org.name'), value: 'name' });
      if (this.$store.state.base.admin.administratedOrg.type === 'Root') {
        h.push({ class: 'error lighten-4', text: this.$t('accAdmin.org.uid'), value: 'uid' });
        h.push({ class: 'error lighten-4', text: this.$t('accAdmin.org.shortname'), value: 'shortname' });
      }
      for (let i = 0; i < this.activeListHeaders.length; i++) {
        if (this.activeListHeaders[i]) {
          h.push(this.availableHeaders[i]);
        }
      }
      h.push({ text: 'menu', value: 'menu', sortable: false });
      return h;
    }
  },
  watch: {
    treeview () {
      if (this.treeview) this.selected = [];
    }
  },
  mounted () {
    this.$emit('setPageMode', 'table');
    if (!this.$store.state.base.admin.loadingOrgs && this.$store.state.base.admin.administratedOrgObjects.orgList.length === 0) {
      this.loadOrgTree();
    }
  },
  methods: {
    async loadOrgTree () {
      reloadOrgList(this.$store.state.base.admin.administratedOrg).then(
        () => {}
      ).catch(
        (messages) => {
          this.$globals.Info.open(messages);
        }
      );
    },
    expanderClicked (selected) {
      if (selected.treeParams.expandable) {
        this.expand(selected, !selected.treeParams.expanded);
      }
    },
    expand (selected, value) {
      selected.treeParams.expanded = value;
      for (const item of this.flattenedTree) {
        if (item.treeParams.parentId === selected.uid) {
          item.treeParams.show = value;
          if (!value) this.expand(item, value);
        }
      }
    },
    treePadding (depth) {
      return { 'padding-left': (parseInt(depth) * 24) + 'px' };
    },
    async setActiveOrg (org, value = null) {
      if (org.uid === this.$store.state.base.org.uid) {
        return;
      }

      if (value == null) value = !org.isActive;
      this.$logger.debug('accAdmin', 'Set Active-flag ' + org.isActive + ' for Org ', org.name);

      try {
        this.$globals.Loader.show();

        if (this.editLogo) {
          await this.uploadLogo();
        } else if (this.removeLogo) {
          await this.deleteUploadedLogo();
        }
        // send data to backend
        let ioData = {
          parentOrg: this.parentOrgUid,
          targetOrg: {
            uid: org.uid,
            shortname: org.shortname,
            name: org.name,
            isActive: value
          }
        };
        this.$restClient.callProcess('accAdmin', 'organisationUpdate', ioData, true).then((processData) => {
          if (isProcessStateOK(processData)) {
            org.isActive = value;
            if (processData.processState.state === 'warning') {
              this.$globals.Info.open(processData.processState.messages);
            }
            this.$logger.debug('accAdmin', 'Saved Org Account Data');
          } else {
            this.$globals.Info.open(processData.processState.messages);
          }
        });
      } finally {
        this.$globals.Loader.hide();
      }
    },
    setActiveOrgs (value) {
      for (const o of this.selected) {
        if (o.uid !== this.$store.state.base.org.uid) {
          if (o.isActive !== value) {
            this.setActiveOrg(o, value);
          }
        }
      }
    },
    async deleteOrgs () {
      let deletedOrgs = [];
      if (await this.$globals.Confirm.yesNoDlg(this.$t('accAdmin.orgList.orgDelete'), this.$t('accAdmin.orgList.orgsDeleteConfirmText'))) {
        let counter = 0;
        for (const org of this.selected) {
          if (org.uid !== this.$store.state.base.org.uid) {
            const ioData = {
              targetOrg: {
                uid: org.uid,
                shortname: org.shortname
              }
            };
            this.$restClient.callProcess('accAdmin', 'organisationDelete', ioData, true).then((processData) => {
              counter++;
              this.$globals.Info.open(processData.processState.messages);
              if (isProcessStateOK(processData)) {
                deletedOrgs.push(org.uid);
                this.deleteOrgGlobalProps(org.uid);
                this.deleteOrgS3Contents(org.shortname);
              }
              if (counter === this.selected.length) {
                this.removeOrgsFromList(deletedOrgs);
              }
            });
          }
        }
      }
    },
    async deleteOrg (org) {
      if (org.uid === this.$store.state.base.org.uid) {
        return;
      }
      // show confirmation dialog
      if (await this.$globals.Confirm.yesNoDlg(this.$t('accAdmin.orgList.orgDelete') + ': ' + org.name, this.$t('accAdmin.orgList.orgDeleteConfirmText'))) {
        const ioData = {
          targetOrg: {
            uid: org.uid,
            shortname: org.shortname
          }
        };
        this.$restClient.callProcess('accAdmin', 'organisationDelete', ioData, true).then((processData) => {
          this.$globals.Info.open(processData.processState.messages);
          if (isProcessStateOK(processData)) {
            this.deleteOrgGlobalProps(org.uid);
            this.deleteOrgS3Contents(org.shortname);
            this.removeOrgsFromList([org.uid]);
          }
        });
      }
    },
    removeOrgsFromList (deletedOrgUids) {
      for (let orgUid of deletedOrgUids) {
        let deletedOrgIndex = this.$store.state.base.admin.administratedOrgObjects.orgList.findIndex((org) => org.uid === orgUid);
        this.$store.state.base.admin.administratedOrgObjects.orgList.splice(deletedOrgIndex, 1);
      }
    },
    async deleteOrgS3Contents (orgShortname) {
      try {
        const assets = this.$configHelper.getModuleConfigParam('acc', 'assets');
        await Promise.all([
          S3Helper.deleteObject(assets.pathOrgBaseSettings + orgShortname + '.json', assets.s3config),
          S3Helper.deleteObject(assets.pathOrgLogo + orgShortname, assets.s3config),
          S3Helper.deleteObject(assets.pathOrgTheme + orgShortname, assets.s3config)
        ]);
      } catch (e) {
        this.$globals.Info.open([ { type: 'systemError', message: e } ]);
      }
    },
    async deleteOrgGlobalProps (orgUid) {
      try {
        let ioData = {
          targetKey: orgUid
          // context: 'orgSettings' => we want to delete all globalProps from deleted org
        };
        let processData = await this.$restClient.callProcess('baseAdmin', 'globalPropertiesDelete', ioData);
        if (!isProcessStateOK(processData)) {
          this.$globals.Info.open(processData.processState.messages);
        }
      } catch (e) {
        this.$globals.Info.open([ { type: 'systemError', message: e } ]);
      }
    },
    createSubOrg (org = null) {
      if (this.$store.state.base.orgSettings.subOrgSettings.createSubOrgs !== true) {
        return;
      }
      if (org == null) {
        org = this.selected[0];
      }
      let routeParams = {
        parentOrgUid: org.uid,
        parentOrgShortname: org.shortname
      };
      this.$router.push({ name: 'accAdminAccountsCreate', params: routeParams });
      // this.$emit('createSubOrg', org);
    },
    editOrg (org = null) {
      if (!org) {
        org = this.selected[0];
      }
      this.$router.push('/accAdmin/accounts/' + org.uid);
      // this.$emit('edit', org);
    },
    clickedRow (org) {
      this.editOrg(org);
    },
    clearFilters () {
      this.filter.name = '';
      this.filter.address1 = '';
      this.filter.address2 = '';
      this.filter.zipCode = '';
      this.filter.city = '';
      this.filter.region = '';
      this.filter.country = '';
      this.filter.parent = '';
      this.applyFilters();
    },
    applyFilters (value, filtername) {
      this.filter[filtername] = value;
      this.$logger.debug('accAdmin', 'applyFilters', JSON.stringify(this.filter));
    }
  }
};
</script>

<style scoped>
.showColumn {
  padding: 0 0px !important;
  width: 0px !important;
}
</style>
